import React from "react";
import { Link, useLocation } from "react-router-dom";

import axios from "helpers/AxiosConfig";

import Stack from "react-bootstrap/Stack";

import HeartIcon from "../../assets/icons/Icon-awesome-heart.svg";
import ShoppinCart from "../../assets/icons/Icon-material-shopping-cart.svg";
import DeleteIcon from "../../assets/icons/Icon-material-delete-forever.svg";

// Notification

export default function SingleCourse(props) {
  const location = useLocation(); // get the website location
  return (
    <div className="ts-course-card ts-course-card--primary">
      <Stack direction="horizontal" className="justify-content-between mb-09">
        {location.pathname === "/wishlist" ? (
          <button className="btn btn-change">
            <img src={DeleteIcon} alt="..." />
          </button>
        ) : (
          <button
            className="btn btn-change"
            onClick={() => props.addCourseToWishList(props.Id)}
          >
            <img src={HeartIcon} alt="..." />
          </button>
        )}
        <h5 className="ts-course-card__title mb-0">{props.CourseTitle}</h5>
        <button
          className="btn btn-change"
          onClick={() => props.addToCart(props.Id)}
        >
          <img src={ShoppinCart} alt="..." />
        </button>
      </Stack>

      <div className="w-100">
        <div className="d-flex justify-content-between  mb-06 text-white  ">
          <div className="d-flex flex-column justify-content-around">
            <h5 className="mb-01 fw-bold">
              <s> $50.00</s>
            </h5>
            <h5 className="mb-01 fw-bold">$29.00</h5>
          </div>
          <div className="d-flex flex-column justify-content-around text-end ">
            <h6 className="mb-01 fw-normal">24 Lessons</h6>
            <h6 className="mb-01 fw-normal text-uppercase">12:34:56 Hrs</h6>
          </div>
        </div>
        <Link
          className="ts-course-card__btn btn"
          to={`/course-detail/${props.Id}`}
        >
          {props.CourseBtn}
        </Link>
      </div>
    </div>
  );
}
