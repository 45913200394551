import React from "react";
import "./video-player.scss";

import { Player } from "video-react";

export default function VideoPlayer(props) {
  return (
    <div>
      <Player poster={props.poster} autoPlay muted>
        <source src={props.src} />
      </Player>
    </div>
  );
}
