// Import Swiper styles
import "swiper/scss";
import "swiper/scss/navigation";
//
import "./home-hero-login-swiper.scss";

import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Pagination, Navigation, EffectCreative } from "swiper";

import * as Icon from "react-bootstrap-icons";

export default function App() {
  return (
    // dir="rtl"
    <Swiper
      loop={true}
      effect={"creative"}
      creativeEffect={{
        prev: {
          shadow: true,
          translate: [0, 0, -800],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      }}
      navigation={{
        nextEl: ".home-hero-swiper-button-next",
        prevEl: ".home-hero-swiper-button-prev",
      }}
      className="homeHeroSwiper"
      pagination={true}
      modules={[Pagination, Navigation, EffectCreative]}
    >
      <SwiperSlide>
        <img src="assets/images/home-slider-image.png" alt="..." />
      </SwiperSlide>
      <SwiperSlide>
        <img src="assets/images/home-slider-image.png" alt="..." />
      </SwiperSlide>
      <SwiperSlide>
        <img src="assets/images/home-slider-image.png" alt="..." />
      </SwiperSlide>

      <button className="swiper-button home-hero-swiper-button-prev">
        <Icon.ArrowLeftCircle />
      </button>
      <button className="swiper-button home-hero-swiper-button-next ">
        <Icon.ArrowRightCircle />
      </button>
    </Swiper>
  );
}
