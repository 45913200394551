import "./promo-gift-header.scss";
import React from "react";
import { Link } from "react-router-dom";

import RehamLogoEnglishArabic from "../../assets/images/reham-logo-arabic-white.png";
import RehamLogoEnglishWhite from "../../assets/images/reham-logo-english-white.png";

export default function PromoGiftHeader(props) {
  return (
    <header className="ts-promo-gift-header ">
      <Link to="/home">
        <img
          className="ts-promo-gift-header__logo "
          // src={RehamLogoEnglishArabic}
          src={props.rtlSwitch ? RehamLogoEnglishArabic : RehamLogoEnglishWhite}
          alt="..."
        />
      </Link>
    </header>
  );
}
