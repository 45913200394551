import React from "react";
import Stack from "react-bootstrap/Stack";

// data
import localData from "data/data.json";
let pageData = localData.varified_payment;

export function PaymentVarified(props) {
  return (
    <Stack direction="horizontal" gap={3} className="justify-content-center ">
      <img width="50" src="/assets/icons/payment-varified.svg" alt="..." />

      <h5 className="mb-0 mt-3 ">
        {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
      </h5>
    </Stack>
  );
}
export function PaymentVarified2(props) {
  return (
    <Stack gap={3} className="justify-content-center align-items-center ">
      <img width="50" src="/assets/icons/credit-card-blue.svg" alt="..." />

      <h5 className="mb-0 mt-3 ">
        {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
      </h5>
    </Stack>
  );
}
