import React, { useState } from "react";
import axios from "helpers/AxiosConfig";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { X } from "react-bootstrap-icons";

export default function SearchBar(props) {
  const [searchCoursesList, setSearchCoursesList] = useState([]);
  const [isHideSearchDropdown, setIsHideHideDropdown] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  let searchCourses = () => {
    axios
      .get("search_courses", {
        params: {
          key: searchValue,
        },
      })
      .then(function (response) {
        let coursesList = response.data.Result;
        setSearchCoursesList(coursesList);
        setIsHideHideDropdown(true);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };

  return (
    <div className="nav-item ts-search-dropdown">
      <Form>
        <Form.Group
          className="search-group rounded-pill"
          controlId="SearchCourses"
        >
          <Form.Control
            className="ts-search-courses rounded-pill"
            type="text"
            placeholder={
              props.rtlSwitch ? "البحث في الدورات" : "Search Courses"
            }
            onChange={(e) => {
              setSearchValue(e.target.value);
              searchCourses();
            }}
          />
          <div
            className="search-group__close"
            onClick={() => {
              setIsHideHideDropdown(false);
            }}
          >
            <X />
          </div>
          <img
            className="search-group__img"
            src="/assets/icons/material-search.svg"
            alt="search icon"
            onClick={() => {
              setIsHideHideDropdown(true);
            }}
          />
        </Form.Group>
      </Form>
      {isHideSearchDropdown ? (
        <div className="ts-search-dropdown__body">
          <ol>
            {searchCoursesList.map((course, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    setIsHideHideDropdown(false);
                  }}
                >
                  <Link
                    className="ts-search-dropdown__program"
                    to={`/course-detail/${course.id}`}
                  >
                    <img
                      className="ts-search-dropdown__program-img"
                      src={course.thumb_image_url}
                      alt="..."
                    />
                    <div>
                      <h6 className="text-gray-04 mb-1">
                        {props.rtlSwitch ? course.name_ar : course.name_en}
                      </h6>
                      <div
                        className="text-gray-05 mb-0"
                        dangerouslySetInnerHTML={{
                          __html: props.rtlSwitch
                            ? course.description_ar
                            : course.description_en,
                        }}
                      ></div>
                    </div>
                  </Link>
                </li>
              );
            })}
          </ol>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
