import React from "react";
import Form from "react-bootstrap/Form";

export default function TsFormControl(props) {
  return (
    <Form.Group
      className={
        "ts-form-group " + (props.mb === undefined ? "mb-04" : props.mb)
      }
      controlId={props.id}
    >
      <Form.Control
        className="rounded-pill shadow-sm"
        type={props.Type}
        placeholder={props.Placeholder}
        required
        minLength={props.minLength}
        maxLength={props.maxLength}
        onChange={(e) => {
          props.changeValue(e.target.value); // return input value to parent
        }}
        autoComplete={props.autoComplete === "off" ? "new-password" : "on"}
      />

      <div className="ts-form-group__icon-container">
        <img
          className="ts-form-group__icon"
          src={props.Icon}
          alt={props.IconAlt}
        />
      </div>

      <Form.Control.Feedback type="invalid">
        {props.InvalidFeedback}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
