import "./date.scss";
import React, { useState, useEffect } from "react";

export default function DateSection(props) {
  const [currentDay, setCurrentDay] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");
  useEffect(() => {
    let months = [];
    let weekDays = [];

    if (props.rtlSwitch) {
      months = [
        "يناير",
        "شهر فبراير",
        "يمشي",
        "أبريل",
        "مايو",
        "يونيه",
        "يوليو",
        "أغسطس",
        "سبتمبر",
        "اكتوبر",
        "شهر نوفمبر",
        "ديسمبر",
      ];
      weekDays = [
        "الأحد",
        "الاثنين",
        "يوم الثلاثاء",
        "الأربعاء",
        "يوم الخميس",
        "جمعة",
        "السبت",
      ];
    } else {
      months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      weekDays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
    }
    let newDate = new Date();

    setCurrentDate(newDate.getDate());
    setCurrentMonth(months[newDate.getMonth()]);
    setCurrentDay(weekDays[newDate.getDay()]);
  }, []);

  return (
    <div
      className={`ts-date ts-rounded ${
        props.rtlSwitch
          ? "ts-rounded--not-top-left"
          : "ts-rounded--not-bottom-right"
      } `}
    >
      <div>
        {/* <img className="mb-07" src="assets/images/eid-al-fitr.svg" alt="..." /> */}
        <h1>
          {currentDate} <span>{currentMonth}</span>
        </h1>
        <h2>{currentDay}</h2>
      </div>
    </div>
  );
}
