import "./review.scss";
import React, { useEffect, useState } from "react";

import ProfilePicture from "../../assets/icons/profile-picture.svg";

import Stack from "react-bootstrap/Stack";

export default function SingleReview(props) {
  const [reviewUpdatedDate, setReviewUpdatedDate] = useState();
  useEffect(() => {
    // review updated date
    setReviewUpdatedDate(props.comment.updated_at.split("T")[0]);
  }, []);

  return (
    <>
      <div className="ts-review__inner">
        <header className="ts-review__header  d-flex align-items-center">
          <img className="ts-review__user-img" src={ProfilePicture} alt="..." />
        </header>
        <div className="ts-review__main">
          <Stack
            direction="horizontal"
            gap={3}
            className="align-items-center mb-03 flex-wrap"
          >
            <h6 className="ts-review__name text-gray-05 mb-0">
              {props.comment.visitor_name === null
                ? "Ananonymous"
                : props.comment.visitor_name}
            </h6>
            <p className="ts-review__date text-nowrap text-gray-05 fw-bold mb-0">
              {reviewUpdatedDate}
            </p>
          </Stack>
          <p className="ts-review__message text-gray-05 mb-03">
            {props.comment.comment}
          </p>
        </div>
      </div>
    </>
  );
}
