import "./ProgramDetails.scss";
import React, { useEffect, useState } from "react";
import axios from "helpers/AxiosConfig";
// Bootstrap
import { Container, Row, Col } from "react-bootstrap";

import { useParams } from "react-router-dom";
// components
import QualityCirtificate from "components/QualityCirtificate/QualityCirtificate";
import VimeoPlayer from "components/VideoPlayer/Vimeo";
import SeminarSection from "components/Seminar/SeminarSection.jsx";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";

// Component
import PaymentMethods from "components/Payment/PaymentMethods";
import { PaymentVarified } from "components/Payment/PaymentVarified";
import { ButtonGreen } from "components/Buttons/Buttons";
import LessonsTabs from "components/LessonsTabs/LessonsTabs";

// temp start
// import { getVimeoId, getVomeoData } from "get-vimeo-thumbnail";
// temp end

// data
import localData from "data/data.json";
let pageData = localData.program_details;
export default function ProgramDetails(props) {
  const [program, setProgram] = useState([]);
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState([]);
  const [seminar, setSeminar] = useState([]);
  const [totalPrice, setTotalPrice] = useState("");

  const [allCoursesInProgrammId, setAllCoursesInProgrammId] = useState([]);

  const [isAffiliateValid, setIsAffiliateValid] = useState(false);

  let { ProgramId } = useParams();

  useEffect(() => {
    // course details
    getProgramDetails();
  }, [ProgramId]);

  const getProgramDetails = () => {
    axios
      .get(`program_details/${ProgramId}`)
      .then(function (response) {
        let program = response.data.program;
        let Courses = program.courses;
        let Seminar = program.sections;
        let firstCourse = Courses[0];
        let allCroursesId = [];
        setProgram(program);
        setCourses(Courses);
        setSeminar(Seminar);
        setCourse(firstCourse);
        setTotalPrice(program.total_price);

        // console.log(program);
        for (let course of program.courses) {
          allCroursesId.push(course.id);
        }

        setAllCoursesInProgrammId(allCroursesId);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };
  function courseMainFile(filetype) {
    if (filetype === 0) {
      return <VimeoPlayer Url={program.vimeo} />;
    } else {
      return <VideoPlayer src={program.vimeo} />;
    }
  }

  return (
    <>
      <div className="ts-program-details">
        <div className="ts-fixed-buy-now-button fixed-bottom text-center">
          <Container>
            <ButtonGreen
              text={
                props.rtlSwitch
                  ? pageData.buynow_button_ar
                  : pageData.buynow_button_en
              }
              rtlSwitch={props.rtlSwitch}
              totalPrice={totalPrice}
              addToCartMultipleCourses={props.addToCartMultipleCourses}
              allCoursesInProgrammId={allCoursesInProgrammId}
            />
          </Container>
        </div>

        <div className="ts-hero">
          <Container>{courseMainFile(program.type)}</Container>
          {/* <Container>{courseMainFile(program.type)}</Container> */}
        </div>

        {seminar.map((seminarCards, index) => {
          return (
            <SeminarSection
              length={seminar.length}
              rtlSwitch={props.rtlSwitch}
              key={index}
              index={index + 1}
              seminarCards={seminarCards}
            />
          );
        })}

        <QualityCirtificate rtlSwitch={props.rtlSwitch} warranty={program.warranty || '30'} />

        <Container className="ts-section-py mb-14">
          <div className="text-center mb-08">
            <ButtonGreen
              text={
                props.rtlSwitch
                  ? pageData.buynow_button_ar
                  : pageData.buynow_button_en
              }
              rtlSwitch={props.rtlSwitch}
              totalPrice={totalPrice}
              addToCartMultipleCourses={props.addToCartMultipleCourses}
              allCoursesInProgrammId={allCoursesInProgrammId}
            />
          </div>
          <div className="mb-06">
            <PaymentMethods />
          </div>
          <PaymentVarified rtlSwitch={props.rtlSwitch} />
        </Container>

        <section className="ts-key-points ts-section-py mb-14">
          <Container>
            <h3 className="text-center text-primary-02 mb-12">
              {props.rtlSwitch
                ? pageData.key_point_title_ar
                : pageData.key_point_title_en}
            </h3>

            <Row className="justify-content-between gap-5  mb-14">
              <Col lg={5}>
                <div
                  className="ts-key-points-list heading-6 text-gray-05"
                  dangerouslySetInnerHTML={{
                    __html: props.rtlSwitch
                      ? program.description_ar
                      : program.description_en,
                  }}
                ></div>
              </Col>
              <Col lg={5}>
                <ul
                  className="ts-key-points-list heading-6 text-gray-05"
                  dangerouslySetInnerHTML={{
                    __html: props.rtlSwitch
                      ? program.description_ar_2
                      : program.description_en_2,
                  }}
                ></ul>
              </Col>
            </Row>
            <LessonsTabs rtlSwitch={props.rtlSwitch} courses={courses} />
          </Container>
        </section>
        {/* <Container>
        <LessonsTabs />
      </Container> */}
        {/* <Container>
        <Row className="align-items-end">
          <Col sm={12} lg={7}>
            <h5 className="text-center mb-08">هل هذا البرنامج مناسب لي؟</h5>
            <h5 className="mb-04"> هذا البرنامج مناسب لكل من</h5>
            <ul className="heading-6 mb-10">
              <li>يبحث عن حلول لتحسين علاقته العاطفية الحالية</li>
              <li>
                يريد أن يرفع وعيه تجاه الحب و الارتباط العاطفي قبل البدء في
                علاقة
              </li>
              <li>
                يريد أن يتعرف على نفسه أكثر كي يتمكن من فهم الآخر في العلاقة
              </li>
              <li>يريد أن يفهم الانعكاسات و الرسائل في العلاقات العاطفية</li>
              <li>
                لكل من لديه أسئلة تجاه الحب و العلاقات العاطفية و يريد أن يفهم
                (لماذا تسير الأحداث بهذه الطريقة)
              </li>
            </ul>

            <h5 className="text-center mb-08">
              ما هي الأعمال و الدورات السابقة لـ رهام
            </h5>
            <p className="heading-6 mb-14">
              رهام هي امرأة كويتية نشيطة في رسالة "الوعي الأنثوي" و هي أول من
              بدأ في تنشيط حركة العودة للوعي الأنثوي في الوطن العربي خلال
              السنوات القليلة الماضية و قد حصلت مؤخراً برامجها التدريبية التي
              طورتها على تكريم من وزارة الدولة لشئون الشباب في الكويت على أنها
              واحدة من أهم عشرة مشاريع شبابية في القطاع التعليمي رهام هي امرأة
              كويتية نشيطة في رسالة "الوعي الأنثوي" و هي أول من بدأ في تنشيط
              حركة العودة للوعي الأنثوي في الوطن العربي خلال السنوات القليلة
              الماضية و قد حصلت مؤخراً برامجها التدريبية التي طورتها على تكريم
              من وزارة الدولة لشئون الشباب في الكويت على أنها واحدة من أهم عشرة
              مشاريع شبابية في القطاع التعليمي
            </p>
          </Col>
          <Col sm={12} lg={5}>
            <img
              className="w-100"
              src="https://rehamlove.com/Uploads/Content/AboutLeftImg.jpg"
              alt="..."
            />
          </Col>
        </Row>
      </Container> */}
        {/* <div className="ts-love-about ts-section-py">
        <Container>
          <h5 className="text-center mb-10">
            استطاعت رهام و منذ انطلاقتها قبل سنوات أن تساعد الآلاف من السيدات
            على التواصل مع ذاتهم الأنثوية ورفع حبهم و تقديرهم لذاتهم و قد
            استطاعت أن تثبت نفسها في مجال التدريب في زمن قياسي نظراً لإيمانها
            العميق و حبها لرسالتها
          </h5>
        </Container>

        <Row className="w-100 mx-auto">
          <Col sm={6} lg={3}>
            <img
              class="w-100"
              src="https://rehamlove.com/Uploads/Content/Gallery4.jpg"
              alt="..."
            />
          </Col>
          <Col sm={6} lg={3}>
            <img
              class="w-100"
              src="https://rehamlove.com/Uploads/Content/Gallery3.jpg"
              alt="..."
            />
          </Col>
          <Col sm={6} lg={3}>
            <img
              class="w-100"
              src="https://rehamlove.com/Uploads/Content/Gallery2.jpg"
              alt="..."
            />
          </Col>
          <Col sm={6} lg={3}>
            <img
              class="w-100"
              src="https://rehamlove.com/Uploads/Content/Gallery1.jpg"
              alt="..."
            />
          </Col>
        </Row>
      </div> */}
        <Container className="ts-section-py">
          <div className="text-center mb-08">
            <ButtonGreen
              text={
                props.rtlSwitch
                  ? pageData.buynow_button_ar
                  : pageData.buynow_button_en
              }
              rtlSwitch={props.rtlSwitch}
              totalPrice={totalPrice}
              addToCartMultipleCourses={props.addToCartMultipleCourses}
              allCoursesInProgrammId={allCoursesInProgrammId}
            />
          </div>
          <div className="mb-06">
            <PaymentMethods />
          </div>
          <PaymentVarified rtlSwitch={props.rtlSwitch} />
        </Container>
      </div>
    </>
  );
}
