import "./purchase-history.scss";
import React from "react";

import { Container } from "react-bootstrap";
export default function PurchaseHistory() {
  return (
    <div className="ts-purchase-history">
      <Container>
        <div className="ts-purchase-history__table-container">
          <table>
            <thead>
              <tr>
                <th scope="col" colSpan="2">
                  Courses
                </th>
                <th scope="col">Date</th>
                <th scope="col">Price</th>
                <th colSpan="2" scope="col">
                  Payment Type
                </th>
                <th scope="col">Invoice</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="2" data-label="Courses">
                  Lorem ipsum dolor
                </td>
                <td data-label="Date">18/04/2012</td>
                <td data-label="Price">361$</td>
                <td colSpan="2" data-label="Payment Type">
                  Paypal
                </td>
                <td data-label="Invoice">
                  <button className="btn btn-invoice rounded-pill">
                    Paypal
                  </button>
                </td>
              </tr>
              <tr>
                <td colSpan="2" data-label="Courses">
                  Lorem ipsum dolor
                </td>
                <td data-label="Date">18/04/2012</td>
                <td data-label="Price">361$</td>
                <td colSpan="2" data-label="Payment Type">
                  Paypal
                </td>
                <td data-label="Invoice">
                  <button className="btn btn-invoice rounded-pill">
                    Paypal
                  </button>
                </td>
              </tr>
              <tr>
                <td colSpan="2" data-label="Courses">
                  Lorem ipsum dolor
                </td>
                <td data-label="Date">18/04/2012</td>
                <td data-label="Price">361$</td>
                <td colSpan="2" data-label="Payment Type">
                  Paypal
                </td>
                <td data-label="Invoice">
                  <button className="btn btn-invoice rounded-pill">
                    Paypal
                  </button>
                </td>
              </tr>
              <tr>
                <td colSpan="2" data-label="Courses">
                  Lorem ipsum dolor
                </td>
                <td data-label="Date">18/04/2012</td>
                <td data-label="Price">361$</td>
                <td colSpan="2" data-label="Payment Type">
                  Paypal
                </td>
                <td data-label="Invoice">
                  <button className="btn btn-invoice rounded-pill">
                    Paypal
                  </button>
                </td>
              </tr>
              <tr>
                <td colSpan="2" data-label="Courses">
                  Lorem ipsum dolor
                </td>
                <td data-label="Date">18/04/2012</td>
                <td data-label="Price">361$</td>
                <td colSpan="2" data-label="Payment Type">
                  Paypal
                </td>
                <td data-label="Invoice">
                  <button className="btn btn-invoice rounded-pill">
                    Paypal
                  </button>
                </td>
              </tr>
              <tr>
                <td colSpan="2" data-label="Courses">
                  Lorem ipsum dolor
                </td>
                <td data-label="Date">18/04/2012</td>
                <td data-label="Price">361$</td>
                <td colSpan="2" data-label="Payment Type">
                  Paypal
                </td>
                <td data-label="Invoice">
                  <button className="btn btn-invoice rounded-pill">
                    Paypal
                  </button>
                </td>
              </tr>
              <tr>
                <td colSpan="2" data-label="Courses">
                  Lorem ipsum dolor
                </td>
                <td data-label="Date">18/04/2012</td>
                <td data-label="Price">361$</td>
                <td colSpan="2" data-label="Payment Type">
                  Paypal
                </td>
                <td data-label="Invoice">
                  <button className="btn btn-invoice rounded-pill">
                    Paypal
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Container>
    </div>
  );
}
