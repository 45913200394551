import "./faq.scss";
import React, { useState, useEffect } from "react";
import axios from "helpers/AxiosConfig";

// Bootstrap
import { Container, Accordion } from "react-bootstrap";

// local data
import localData from "data/data.json";
let pageData = localData.faq;
export default function Faq(props) {
  const [generalFaq, setGeneralFaq] = useState([]);

  useEffect(() => {
    axios
      .get("general_faq")
      .then(function (response) {
        setGeneralFaq(response.data.general_faq);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  }, []);

  return (
    <section className="ts-faq">
      <Container>
        <h1 className="text-gray-03 mb-11">
          {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
        </h1>
        <Accordion defaultActiveKey={["1"]} flush>
          {generalFaq.map((generalFaqItem) => (
            <Accordion.Item
              key={generalFaqItem.id}
              eventKey={generalFaqItem.id}
            >
              <Accordion.Header className="rounded-pill">
                <span>{generalFaqItem.question_en}</span>
              </Accordion.Header>
              <Accordion.Body
                dangerouslySetInnerHTML={{
                  __html: generalFaqItem.answer_en,
                }}
              ></Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Container>
    </section>
  );
}
