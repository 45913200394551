import "./paymentInvoice.scss";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// data
import localData from "data/data.json";
let pageData = localData.payment_invoice;

export default function PaymentInVoice(props) {
  const [status, setState] = useState("");
  const [paymentId, sePaymentId] = useState("");
  const [trackId, setTrackId] = useState("");
  const [amount, setAmount] = useState("");

  const location = useLocation();

  useEffect(() => {
    let urlValues = location.search.split("?")[1].split("&");
    setState(urlValues[0].split("=")[1]);
    sePaymentId(urlValues[1].split("=")[1]);
    setTrackId(urlValues[2].split("=")[1]);
    setAmount(urlValues[3].split("=")[1]);
  }, [location.search]);

  return (
    <section className="payment-invoices">
      <Container>
        <div className="payment-invoices__details-container  ">
          <div className="text-center">
            <img
              width="137"
              className="mb-10 mx-auto"
              src={
                props.rtlSwitch
                  ? "/assets/images/reham-logo-arabic-white.png"
                  : "/assets/icons/reham-diva-program.svg"
              }
              alt="reham diva program logo"
            />
          </div>
          <div className="payment-invoices__details-container-inner ts-rounded ts-rounded--not-bottom-right">
            <div className="payment-invoices__content">
              {status === "success" ? (
                <ul className="list-unstyled ">
                  <li className="mb-06">
                    <Row>
                      <Col md={4}>
                        <h6>
                          {props.rtlSwitch
                            ? pageData.name_ar
                            : pageData.name_en}
                        </h6>
                      </Col>
                      <Col md={8}>
                        <h6>{props.userInfo.name}</h6>
                      </Col>
                    </Row>
                  </li>
                  <li className="mb-06">
                    <Row>
                      <Col md={4}>
                        <h6>
                          {props.rtlSwitch
                            ? pageData.email_ar
                            : pageData.email_en}
                        </h6>
                      </Col>
                      <Col md={8}>
                        <h6 className="text-break">{props.userInfo.email}</h6>
                      </Col>
                    </Row>
                  </li>
                  <li className="mb-06">
                    <Row>
                      <Col md={4}>
                        <h6>
                          {props.rtlSwitch
                            ? pageData.payment_id_ar
                            : pageData.payment_id_en}
                        </h6>
                      </Col>
                      <Col md={8}>
                        <h6>{paymentId}</h6>
                      </Col>
                    </Row>
                  </li>

                  <li className="mb-06">
                    <Row>
                      <Col md={4}>
                        <h6>
                          {props.rtlSwitch
                            ? pageData.track_id_ar
                            : pageData.track_id_en}
                        </h6>
                      </Col>
                      <Col md={8}>
                        <h6>{trackId}</h6>
                      </Col>
                    </Row>
                  </li>
                  <li className="mb-12">
                    <Row>
                      <Col md={4}>
                        <h6>
                          {props.rtlSwitch
                            ? pageData.amount_ar
                            : pageData.amount_en}
                        </h6>
                      </Col>
                      <Col md={8}>
                        <h6>
                          {props.rtlSwitch ? "KD" : ""}
                          &nbsp;
                          {amount}
                          &nbsp;
                          {props.rtlSwitch ? "" : "KD"}
                        </h6>
                      </Col>
                    </Row>
                  </li>
                </ul>
              ) : (
                <h6 className="text-center mb-14">
                  {props.rtlSwitch
                    ? pageData.payment_unscessfull_ar
                    : pageData.payment_unscessfull_en}
                </h6>
              )}

              <Link
                className="ts-ceckout-card__btn btn btn-lg btn-primary-07 btn--border-white w-100 fw-bold mb-0 rounded-pill"
                to={status === "success" ? "/my-programs" : "/cart"}
              >
                {status === "success"
                  ? props.rtlSwitch
                    ? pageData.button_success_ar
                    : pageData.button_success_en
                  : props.rtlSwitch
                  ? pageData.button_fail_ar
                  : pageData.button_fail_en}
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
