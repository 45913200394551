import "./progress-bar.scss";
import ProgressBar from "react-bootstrap/ProgressBar";

export default function ProgramProgressBar(props) {
  return (
    <>
      <h4 className="text-center">{props.Progress} %</h4>
      <ProgressBar animated now={props.Progress} />
    </>
  );
}
