import React from "react";

import { Col, Row } from "react-bootstrap";
// Components
import SeminarCard from "./SeminarCard";

export default function SeminarCardGroup(props) {
  return (
    <Row className="ts-seminar-card-group">
      {props.seminarCards.elements.map((element, index) => {
        return (
          <Col key={index}>
            <SeminarCard rtlSwitch={props.rtlSwitch} card={element} />
          </Col>
        );
      })}
    </Row>
  );
}
