import React from "react";
import "./program-menu-title-card.scss";
export default function ProgramMenuTitleCard(props) {
  return (
    <div
      className={
        "ts-program-menu-title-card  shadow-sm ts-rounded ts-rounded--not-top-left " +
        (props.isActive === "active"
          ? "ts-program-menu-title-card--active"
          : "")
      }
    >
      <h4 className="text-gray-04 mb-07">{props.title}</h4>
      <img width="104" src={props.img} alt="reham program title " />
    </div>
  );
}
