import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
// Components
import SeminarCardGroup from "./SeminarCardGroup";

export default function SeminarSection(props) {
  const [sectionColor, setSectionColor] = useState("");
  // temporary
  useEffect(() => {
    let pagIndex = props.index;
    for (let i = 1; i < props.length + 1; i = i + 3) {
      if (pagIndex === i) {
        setSectionColor("ts-seminar--primary");
      }
    }
    for (let i = 2; i < props.length + 1; i = i + 3) {
      if (pagIndex === i) {
        setSectionColor("ts-seminar--secondary");
      }
    }
    for (let i = 3; i < props.length + 1; i = i + 3) {
      if (pagIndex === i) {
        setSectionColor("ts-seminar--white");
      }
    }
  }, [props.index, props.length]);

  return (
    <section className={`ts-seminar ts-section-py ${sectionColor} `}>
      <Container>
        <h1 className="text-center mb-14">
          {props.rtlSwitch
            ? props.seminarCards.title_ar
            : props.seminarCards.title_en}
        </h1>
        <SeminarCardGroup
          seminarCards={props.seminarCards}
          rtlSwitch={props.rtlSwitch}
        />
      </Container>
    </section>
  );
}
