import React from "react";
import Form from "react-bootstrap/Form";

export default function TsFormControl(props) {
  return (
    <Form.Group
      className={
        "ts-form-group " + (props.mb === undefined ? "mb-04" : props.mb)
      }
      controlId={props.id}
    >
      {/* <div className="position-relative"> */}

      <Form.Select
        className="rounded-pill shadow-sm"
        type={props.Type}
        placeholder={props.Placeholder}
        onChange={(e) => {
          props.changeValue(e.target.value); // return input value to parent
        }}
        required
      >
        <option value="" disabled selected>
          {props.defaultDisabledOption}
        </option>
        {props.dataArray.map((coutry) => {
          return (
            <option
              key={coutry.id}
              value={coutry.id}
              data-coutry-code={coutry.phone}
            >
              {`(+${coutry.phone} ) ${coutry.name}`}
            </option>
          );
        })}
      </Form.Select>

      <div className="ts-form-group__icon-container">
        <img
          className="ts-form-group__icon"
          src={props.Icon}
          alt={props.IconAlt}
        />
      </div>
    </Form.Group>
  );
}
