import React, { useState } from "react";

import axios from "helpers/AxiosConfig";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";

import { toast } from "react-toastify";

// data
import localData from "data/data.json";
let pageData = localData.comments;

export default function PostComment(props) {
  const [commentCharCount, setCommentCharCount] = useState(0);
  const [comment, setComment] = useState("");

  // Post comment for admin approval
  let postComment = (parentId) => {
    if (comment !== "") {
      axios
        .post(`post_comment`, {
          lesson_id: props.lessonId,
          course_id: props.courseId,
          bonus_material_id: props.bonusMaterialId,
          comment: comment,
          parent_id: props.parentId,
        })
        .then(function (response) {
          let message = response.data.message;
          toast.success(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          // Reset comment area field
          setComment("");
          setCommentCharCount(0);
        })
        .catch(function (error) {
          console.log(error); // handle error
        });
    } else {
      let message = "empty comment not allowed";
      toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <Form className="ts-review-post mb-04">
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Stack
            direction="horizontal"
            className="justify-content-between mb-06"
          >
            <Form.Label className="heading-1">
              {props.rtlSwitch
                ? pageData.post.title_ar
                : pageData.post.title_en}
            </Form.Label>
            <h1>{commentCharCount}</h1>
          </Stack>
          <Form.Control
            as="textarea"
            rows={4}
            value={comment}
            placeholder={
              props.rtlSwitch
                ? pageData.post.textarea_placeholder_ar
                : pageData.post.textarea_placeholder_en
            }
            onChange={(e) => {
              setComment(e.target.value);
              setCommentCharCount(e.target.value.length);
            }}
          />
        </Form.Group>

        <div className="text-end">
          <Button
            variant="primary-01"
            className="btn btn--border-white btn-lg px-5 w-auto "
            onClick={() => postComment()}
          >
            {props.rtlSwitch
              ? pageData.post.submit_button_ar
              : pageData.post.submit_button_en}
          </Button>
        </div>
      </Form>
    </>
  );
}
