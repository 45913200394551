import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Container from "react-bootstrap/Container";

import TsVimeoPlayer from "components/VideoPlayer/Vimeo";
import CustomForm from "./CustomForm";

import PageNotFound from "../404/PageNotFound";

// import the component
import MailchimpSubscribe from "react-mailchimp-subscribe";

const REACT_APP_MAILCHIMP_U = "f9ca257be2b890b55df3d95d4";
const REACT_APP_MAILCHIMP_ID = "e8cdc9d62e";

const postUrl = `https://rehamhouse.us9.list-manage.com/subscribe/post?u=${REACT_APP_MAILCHIMP_U}&id=${REACT_APP_MAILCHIMP_ID}`;

export default function GiftPage() {
  let params = useParams();
  const [isPageNotFound, setIsPageNotFound] = useState(false);

  const [noFormPages, setNoFormPages] = useState(false);
  const [currentPageData, setCurrentPage] = useState({
    title: "",
    videoUrl: "",
  });

  useEffect(() => {
    const pageListIdArray = [];
    const getPagesData = [
      {
        title: null,
        videoUrl: "https://vimeo.com/manage/videos/755676830",
      },
      {
        title: null,
        videoUrl: "https://vimeo.com/manage/videos/755676929",
      },
      {
        title: null,
        videoUrl: "https://vimeo.com/manage/videos/755677001",
      },
      {
        title: null,
        videoUrl: "https://vimeo.com/manage/videos/755677068",
      },
      {
        title: null,
        videoUrl: "https://vimeo.com/manage/videos/755677115",
      },
      {
        title: null,
        videoUrl: "https://vimeo.com/manage/videos/755677182",
      },
      {
        title: '"قصة الآنسة "واااو',
        videoUrl: "https://vimeo.com/755680999",
      },
      {
        title: "هل أنتِ لطيفه أم عنيفة مع ذاتك؟",
        videoUrl: "https://vimeo.com/755680877",
      },
      {
        title: "هل تكرهين الأنوثة؟",
        videoUrl: "https://vimeo.com/757085388",
      },
      // new
      {
        title: 'ودك تكونين "وااو" ؟ ',
        videoUrl: "https://vimeo.com/755680999",
      },
      {
        title: "تحققين أهدافك بأنوثة أم ذكورة؟",
        videoUrl: "https://vimeo.com/755680877",
      },
      {
        title: "هل تكرهين الأنوثة؟",
        videoUrl: "https://vimeo.com/757085388",
      },
    ];

    // Get the list of page Without title
    let pageWithoutForm = [];
    for (let page of getPagesData) {
      let urlCodeOfVideo = page.videoUrl.split("/").pop();
      pageListIdArray.push(urlCodeOfVideo);
      if (page.title) {
        pageWithoutForm.push(urlCodeOfVideo);
      }
    }

    // get page number from key array
    if (pageListIdArray.includes(params.pageId)) {
      for (let [index, val] of pageListIdArray.entries()) {
        if (val === params.pageId) {
          setCurrentPage(getPagesData[index]);
        }
      }
    } else {
      setIsPageNotFound(true);
    }
    pageWithoutForm.forEach((page) => {
      if (page === params.pageId) {
        setNoFormPages(true);
      }
    });
  }, []);

  return (
    <div className="pt-5 mw-1000 mx-auto">
      <Container>
        {isPageNotFound ? (
          <PageNotFound />
        ) : (
          <>
            {noFormPages ? (
              <h2 className="text-center text-primary-07 mb-08">
                {currentPageData.title}
              </h2>
            ) : (
              ""
            )}
            <TsVimeoPlayer
              Url={currentPageData.videoUrl}
              addClassName="mb-10"
            />

            {noFormPages ? (
              ""
            ) : (
              <>
                <MailchimpSubscribe
                  url={postUrl}
                  render={({ subscribe, status, message }) => (
                    <CustomForm
                      status={status}
                      message={message}
                      onValidated={(formData) => {
                        subscribe(formData);
                      }}
                    />
                  )}
                />
              </>
            )}
          </>
        )}
      </Container>
    </div>
  );
}
