import "./login-and-register.scss";
import React, { useState, useEffect } from "react";

import axios from "helpers/AxiosConfig";
import { Link, useNavigate } from "react-router-dom";

// Bootstrap
import { Container, Form, Button } from "react-bootstrap";

import TSFormControl from "./TsFormControl";

import { SaveUserDataToLocalStorage } from "common/js/LoginHelpers";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// data
import localData from "data/data.json";
let pageData = localData.login;

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let userInfo = localStorage.getItem("user-info");
    let cartItemInLocalStorage = localStorage.getItem("add_to_Cart_courses");

    if (userInfo && userInfo !== "undefined") {
      if (cartItemInLocalStorage) {
        props.addToCartMultipleCourses(JSON.parse(cartItemInLocalStorage));
        navigate("/cart");
      } else {
        navigate("/home");
      }
    }
  }, [navigate]);
  // temp navigate added for temp

  let getLoginForm = async (event) => {
    event.preventDefault(); // prevent default behaviour

    // bootstrap Validation
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault(); // prevent default behaviour
      event.stopPropagation();
    }
    setValidated(true);

    axios
      .post("login", {
        email: email,
        password: password,
      })
      .then((response) => {
        let userData = JSON.stringify(response.data.data);
        let userToken = JSON.stringify(response.data.token);

        let status = response.status;
        if (status === 200) {
          toast.success(
            props.rtlSwitch
              ? pageData.successfully_login_ar
              : pageData.successfully_login_en,
            {
              position: "bottom-right",
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          // Save data to local storage
          SaveUserDataToLocalStorage(userData, userToken);
          // Sendin data to paren components for chacing user information
          props.toggleLoginData(true, userData);
          window.isLogged = true;

          navigate("/home");
        } else {
          props.toggleLogin(false);
        }
      })
      .catch(function (error) {
        let message = error.response.data.error;
        toast.error(message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(error); // handle error
      });
  };

  return (
    <section className="ts-login">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Container>
        <div className="ts-login__form-container  ">
          <div className="text-center">
            <img
              width="137"
              className="mb-10 mx-auto"
              src={
                props.rtlSwitch
                  ? "/assets/images/reham-logo-arabic-white.png"
                  : "/assets/icons/reham-diva-program.svg"
              }
              alt="reham diva program logo"
            />
          </div>
          <div className="ts-login__form-container-inner ts-rounded ts-rounded--not-bottom-right">
            <Form
              className="ts-login__form mb-05"
              noValidate
              validated={validated}
            >
              <h3 className="text-primary-03 mb-06 ">
                {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
              </h3>
              <h4 className="text-primary-03 fw-normal mb-08">
                {props.rtlSwitch ? pageData.desc_ar : pageData.desc_en}
              </h4>

              <h1>{props.isLogged}</h1>
              <TSFormControl
                mb="mb-07"
                Icon="/assets/icons/email_primary-icon.svg"
                IconAlt="mail icon"
                Placeholder={
                  props.rtlSwitch
                    ? pageData.email_placeholder_ar
                    : pageData.email_placeholder_en
                }
                Type="email"
                changeValue={(value) => {
                  setEmail(value);
                }}
                InvalidFeedback={
                  props.rtlSwitch
                    ? pageData.email_invalid_message_ar
                    : pageData.email_invalid_message_en
                }
              />
              <TSFormControl
                mb="mb-08"
                Icon="/assets/icons/password-icon.svg"
                IconAlt="mail icon"
                Placeholder={
                  props.rtlSwitch
                    ? pageData.password_placeholder_ar
                    : pageData.password_placeholder_en
                }
                InvalidFeedback={
                  props.rtlSwitch
                    ? pageData.password_invalid_message_ar
                    : pageData.password_invalid_message_en
                }
                Type="password"
                changeValue={(value) => {
                  setPassword(value);
                }}
              />

              <div className="mb-08 text-end">
                <Link
                  className="heading-6 fw-bold text-primary-03 text-decoration-none"
                  to="/forget-password"
                >
                  {props.rtlSwitch
                    ? pageData.forget_password_ar
                    : pageData.forget_password_en}
                </Link>
              </div>
              <Button
                className="btn-submit btn-primary-01 rounded-pill btn-lg w-100 btn--border-white shadow-sm "
                type="submit"
                onClick={getLoginForm}
              >
                {props.rtlSwitch ? pageData.button_ar : pageData.button_en}
                <img
                  className="btn-img-end"
                  src="/assets/icons/arro-enter-right.svg"
                  alt="arrow right enter"
                />
              </Button>
            </Form>

            <p className="text-center fw-bold">
              {props.rtlSwitch
                ? pageData.go_to_register_ar
                : pageData.go_to_register_en}
              &nbsp;
              <Link
                className="text-decoration-none text-primary-02 "
                to="/register"
              >
                {props.rtlSwitch
                  ? pageData.go_to_register_link_ar
                  : pageData.go_to_register_link_en}
              </Link>
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
}
