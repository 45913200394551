import React, { useState, useEffect } from "react";
// react router
import { useParams } from "react-router-dom";
// Bootstrap
import { Container, Stack } from "react-bootstrap";

import Chapter from "components/Chapter/Chapter";
// Components
import TsBreadCrumb from "components/TsBreadCrumb/TsBreadCrumb";

export default function Course(props) {
  const [CourseData, setCourseData] = useState([]);
  const [ChaptersData, setChaptersData] = useState([]);
  const [breadCrumbList, setBreadCrumbList] = useState([]);
  let { courseNumber } = useParams();
  useEffect(() => {
    for (let course of props.myCourses) {
      if (course.id === parseInt(courseNumber)) {
        setCourseData(course);
        setChaptersData(course.chapters);
        // setChaptersData(chapters);
        if (props.rtlSwitch) {
          setBreadCrumbList([
            { name: "برامجي", routeLink: "/my-programs" },
            { name: course.name_ar, routeLink: `/course/${course.id}` },
          ]);
        } else {
          setBreadCrumbList([
            { name: "My programs", routeLink: "/my-programs" },
            { name: course.name_en, routeLink: `/course/${course.id}` },
          ]);
        }
      }
    }
  }, [courseNumber, props.myCourses, props.rtlSwitch]);


  useEffect(() => {
    props.updateCourseId(courseNumber);
  }, [courseNumber]);
  return (
    <div className="ts-page-content">
      <Container className="pt-xxl-4">
        <TsBreadCrumb ItemList={breadCrumbList} />
        <h3 className="mb-05 ">
          {props.rtlSwitch ? CourseData.name_ar : CourseData.name_en}
        </h3>
        <p
          className="ts-desc-md mb-04 mw-lg-700 mb-11"
          dangerouslySetInnerHTML={{
            __html: props.rtlSwitch
              ? CourseData.description_ar
              : CourseData.description_en,
          }}
        ></p>
        <Stack className="gap-4">
          {ChaptersData.map((data, index) => {
            return (
              <Chapter rtlSwitch={props.rtlSwitch} key={index} data={data} />
            );
          })}
        </Stack>
      </Container>
    </div>
  );
}
