import React, { useEffect, useState } from "react";

import axios from "helpers/AxiosConfig";

import { Link } from "react-router-dom";

import NavDropdown from "react-bootstrap/NavDropdown";

export default function ProgramsDropDown(props) {
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    getPrograms();
  }, []);

  const getPrograms = () => {
    axios
      .get("programs")
      .then(function (response) {
        setPrograms(response.data.programs);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };
  return (
    <NavDropdown
      className="rounded-pill ts-programs-dropdown"
      title={props.rtlSwitch ? "البرامج" : "Programs"}
      id="basic-nav-dropdown"
    >
      {programs?.map((program, index) => {
        return (
          <Link
            key={index}
            to={`program-detail/${program.id}`}
            className="dropdown-item"
          >
            {props.rtlSwitch ? program.name_ar : program.name_en}
          </Link>
        );
      })}
    </NavDropdown>
  );
}
