import "./bonus-material.scss";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import Container from "react-bootstrap/Container";

// Components
// import ReviewsGroup from "components/Review/ReviewsGroup";
import TsBreadCrumb from "components/TsBreadCrumb/TsBreadCrumb";
import axios from "helpers/AxiosConfig";

// import VideoPlayer from "components/VideoPlayer/VideoPlayer";
// import VimeoPlayer from "components/VideoPlayer/Vimeo";

// data
import localData from "data/data.json";
let pageData = localData.bonus_material;

export default function BonusMaterial(props) {
  const [bonusMaterial, setBonusMaterial] = useState([]);
  const [currentCourseNumber, setCurrentCourseNumber] = useState();

  let { courseNumber } = useParams();

  const [breadCrumbList, setBreadCrumbList] = useState([
    { name: "My programs", routeLink: "/my-programs" },
    { name: "Course 1", routeLink: `/course/${courseNumber}` },
    {
      name: "Bonus Materials",
      routeLink: `/course/${courseNumber}/bonus-materials`,
    },
  ]);

  useEffect(() => {
    updateBreadCrumbList();
    getBonusMaterials();
    setCurrentCourseNumber(courseNumber);
  }, [props.rtlSwitch]);

  const updateBreadCrumbList = () => {
    if (props.rtlSwitch) {
      setBreadCrumbList([
        { name: "برامجي", routeLink: "/my-programs" },
        {
          name: `${courseNumber}الدورة `,
          routeLink: `/course/${courseNumber}`,
        },
        {
          name: "مادة إضافية",
          routeLink: `/course/${courseNumber}/bonus-materials`,
        },
      ]);
    } else {
      setBreadCrumbList([
        { name: "My programs", routeLink: "/my-programs" },
        {
          name: `Course ${courseNumber}`,
          routeLink: `/course/${courseNumber}`,
        },
        {
          name: "Bonus Materials",
          routeLink: `/course/${courseNumber}/bonus-materials`,
        },
      ]);
    }
  };

  const getBonusMaterials = () => {
    axios
      .get("bonus_material", {
        params: {
          course_id: courseNumber,
        },
      })
      .then(function (response) {
        setBonusMaterial(response.data.data);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };
  return (
    <Container className="py-5">
      <TsBreadCrumb ItemList={breadCrumbList} />
      <h3 className="mb-14">
        {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
      </h3>
      {bonusMaterial.map((bonusMaterialItem, index) => {
        return (
          <Link
            key={index}
            to={`/course/${currentCourseNumber}/bonus-material/${bonusMaterialItem.id}`}
            className="ts-bonus-material-list-item mb-10"
          >
            <h5 className="text-gray-05 mb-0">
              {props.rtlSwitch
                ? bonusMaterialItem.name_ar
                : bonusMaterialItem.name_en}
            </h5>
          </Link>
        );
      })}
    </Container>
  );
}
