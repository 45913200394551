import "./lessons.scss";
import React from "react";
import Lesson from "./Lesson";

export default function Lessons(props) {
  let toggleCompletion = (e) => {
    props.toggleCompletion(e);
  };
  return (
    <ul className="ts-lessons">
      {props.lessons.map((lesson, index) => {
        return (
          <Lesson
            localData={props.localData}
            key={index}
            lessonNumber={index + 1}
            lesson={lesson}
            rtlSwitch={props.rtlSwitch}
            toggleCompletion={toggleCompletion}
          />
        );
      })}
    </ul>
  );
}
