import "./technical-support.scss";
import React, { useState } from "react";
import axios from "helpers/AxiosConfig";
// Bootstrap
import { Container, Stack, Form, Button } from "react-bootstrap";
// Tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// data
import localData from "data/data.json";
let pageData = localData.technical_support;

export default function TechnicalSupport(props) {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  // Bootstrap validation
  const [validated, setValidated] = useState(false);

  const getTechnicalSupport = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    const sendingDataToServer = new Promise((resolve, reject) => {
      axios
        .post("technical_support", {
          subject: subject,
          message: message,
        })
        .then((response) => {
          resolve();
        })
        .catch(function (error) {
          console.log(error); // handle error
          reject();
        });
    });

    // sending data to server
    toast.promise(sendingDataToServer, {
      pending: "Sending your Message to our Technical Team",
      success: "Email Sent Successfully to System Admin",
      error: "Your message is not sent to our Team",

      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <section className="ts-technical-support">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Container>
        <h1 className="text-gray-02 mb-08">
          {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
        </h1>
        <Stack className="mb-08" gap={3} direction="horizontal">
          <a
            href="mailto:hello@reham.com"
            className="btn btn-primary btn-primary-07 rounded-pill"
          >
            {props.rtlSwitch
              ? pageData.button_email_ar
              : pageData.button_email_en}
          </a>
          <a
            href="https://wa.me/+96550406406"
            className="btn btn-primary btn-primary-07 rounded-pill"
          >
            {props.rtlSwitch
              ? pageData.button_whatsapp_ar
              : pageData.button_whatsapp_en}
          </a>
        </Stack>
        <Form noValidate validated={validated}>
          <Form.Group className="mb-08" controlId="tsSubject">
            <Form.Label className="heading-4 fw-bold text-gray-05 mb-05">
              {props.rtlSwitch
                ? pageData.subject_name_ar
                : pageData.subject_name_en}
            </Form.Label>
            <Form.Control
              type="text"
              size="lg"
              required
              onChange={(e) => {
                setSubject(e.target.value); // return input value to parent
              }}
            />
          </Form.Group>
          <Form.Group className="mb-09" controlId="tsMessage">
            <Form.Label className="heading-4 fw-bold text-gray-05 mb-05">
              {props.rtlSwitch ? pageData.message_ar : pageData.message_en}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={10}
              required
              onChange={(e) => {
                setMessage(e.target.value); // return input value to parent
              }}
            />
          </Form.Group>
          <div className="text-end">
            <Button
              className="btn-primary rounded-pill "
              variant="primary-07"
              type="submit"
              onClick={getTechnicalSupport}
            >
              {props.rtlSwitch
                ? pageData.button_submit_ar
                : pageData.button_submit_en}
            </Button>
          </div>
        </Form>
      </Container>
    </section>
  );
}
