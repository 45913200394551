import React from "react";
import "./courses.scss";

import WishListCard from "./WishListCard";

export default function CoursesGroup1(props) {
  return (
    <div
      className={
        "ts-courses-group " +
        (props.groupCol === "2" ? "ts-courses-group--2-cards" : "")
      }
    >
      {props.wishListCards.map((course, index) => {
        return (
          <WishListCard
            key={index}
            CourseTitle={props.rtlSwitch ? course.name_ar : course.name_en}
            CourseBtn={props.rtlSwitch ? "تفاصيل الدورة" : "Course Detail"}
            CourseDesc={
              props.rtlSwitch ? course.description_ar : course.description_en
            }
            CourseId={course.id}
            removeCourseToWishList={props.removeCourseToWishList}
            addToCart={props.addToCart}
          />
        );
      })}
    </div>
  );
}
