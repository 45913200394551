import "./PromoGiftFooter.scss";
import React from "react";
import Container from "react-bootstrap/Container";

export default function PromoGiftFooter() {
  return (
    <footer className="ts-promo-gift-footer">
      <div className="mw-1000 mx-auto">
        <Container>
          <a className="text-decoration-none" href="#">
            hello&copy;reham.com
          </a>
          <p className="float-end">&copy;Reham.com</p>
        </Container>
      </div>
    </footer>
  );
}
