import React from "react";
import Stack from "react-bootstrap/Stack";

export default function PaymentMethods() {
  return (
    <Stack
      direction="horizontal"
      gap={3}
      className="flex-wrap justify-content-center"
    >
      <a href="#">
        <img height="55" src="/assets/icons/visa.svg" alt="..." />
      </a>
      <a href="#">
        <img
          height="55"
          src="/assets/icons/mastercard-svgrepo-com.svg"
          alt="..."
        />
      </a>
      <a href="#">
        <img height="55" src="/assets/icons/knet.png" alt="..." />
      </a>
      <a href="#">
        <img height="55" src="/assets/icons/paypal.svg" alt="..." />
      </a>
    </Stack>
  );
}
