import "./courses.scss";
import React from "react";
import { Link } from "react-router-dom";
export default function ProgramCard(props) {
  return (
    <div className="ts-course-card ts-course-card--primary ">
      <h4 className="ts-course-card__title mb-09">
        {props.rtlSwitch ? props.course.name_ar : props.course.name_en}
      </h4>

      <div>
        <p className=" ts-desc-xs mb-0">
          {props.rtlSwitch ? props.course.name_ar : props.course.name_en}
        </p>
        <p className=" ts-desc-xs mb-03">0 % Completed</p>
        <Link
          className="ts-course-card__btn btn"
          to={"/course/" + props.course.id}
        >
          {props.rtlSwitch ? "ابدأ الدرس" : "Start lesson"}
        </Link>
      </div>
    </div>
  );
}
