import "./_inspiring-stories.scss";
import React from "react";

export default function InspiringStoriesCard(props) {
  return (
    <div className="ts-inspiring-stories-card ts-rounded-sm ts-rounded--not-top-right">
      <img
        className="ts-inspiring-stories-card__img rounded-5"
        src="/assets/images/inspiring-storeis-placeholder-ing.png"
        alt="..."
      />
      <h5>{props.story.user.name}</h5>
      <p className="ts-desc-xs mb-0">{props.story.comment}</p>
    </div>
  );
}
