import "./payment-method.scss";
import React from "react";

import { Container, Form, Stack, Row, Col, Button } from "react-bootstrap";

// data
import localData from "data/data.json";
let pageData = localData.payment_method;
export default function PaymentMethod(props) {
  return (
    <div className="ts-profile">
      <Container>
        <Row className="align-items-center ">
          <Col lg={4} className="text-center mb-08 text-lg-start mb-lg-0 ">
            <img
              width="353"
              className="mw-100  ts-rounded-sm"
              src="/assets/images/place-holder-image-dark.png"
              alt="user profile"
            />
          </Col>
          <Col lg={8}>
            <Form>
              <h2 className="text-gray-05 mb-07 fw-normal">
                {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
              </h2>
              <Stack className=" ts-form-item-group flex-row flex-wrap mb-08">
                <div className="ts-form-item ">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="heading-6 text-gray-05">
                      {props.rtlSwitch
                        ? pageData.card_number_ar
                        : pageData.card_number_en}
                    </Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                </div>

                <div className="ts-form-item ">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="heading-6 text-gray-05">
                      {props.rtlSwitch ? pageData.cvv_ar : pageData.cvv_en}
                    </Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                </div>
                <div className="ts-form-item ">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="heading-6 text-gray-05">
                      {props.rtlSwitch
                        ? pageData.expires_ar
                        : pageData.expires_en}
                    </Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                </div>
                <div className="ts-form-item ">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="heading-6 text-gray-05">
                      {props.rtlSwitch
                        ? pageData.billing_address_ar
                        : pageData.billing_address_en}
                    </Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                </div>
              </Stack>

              <Button className="btn btn-primary-04" type="submit">
                {props.rtlSwitch
                  ? pageData.attach_btn_ar
                  : pageData.attach_btn_en}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
