import React from "react";
import { Link } from "react-router-dom";

export default function SingleCourse(props) {
  return (
    <div className="ts-course-card ts-course-card--primary">
      <h4 className="ts-course-card__title mb-09">{props.CourseName}</h4>
      <div>
        <Link
          to={`/course-detail/${props.Id}`}
          className="ts-course-card__btn btn"
        >
          {props.CourseBtn}
        </Link>
      </div>
    </div>
  );
}
