import "./_inspiring-stories.scss";
import React from "react";

import InspiringStoriesCard from "./InspiringStoriesCard";

export default function InspiringStoriesCardGroup(props) {
  return (
    <div className="ts-inspiring-stories-card-group ">
      {props.stories.map((story, index) => {
        return (
          <div
            type="button"
            onClick={() => props.showModal(story.id)}
            key={index}
          >
            <InspiringStoriesCard story={story} rtlSwitch={props.rtlSwitch} />
          </div>
        );
      })}
    </div>
  );
}
