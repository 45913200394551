import "./reset-password.scss";
import React, { useState } from "react";
import axios from "helpers/AxiosConfig";
// Bootstrap
import { Container, Form, Row, Col, Button } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ProfilePicture from "../../assets/icons/profile-picture.svg";
import { useNavigate } from "react-router-dom";
// data
import localData from "data/data.json";
let pageData = localData.reset_password;

export default function UserResetPassword(props) {
  const [validated, setValidated] = useState(false); // bootstrap form validator

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const user_id = props.userInfo.id;
  const navigate = useNavigate();

  const resetPassword = async (event) => {
    event.preventDefault(); // prevent default behaviour
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    if (
      newPassword === confirmNewPassword &&
      newPassword.length > 5 &&
      confirmNewPassword.length > 5
    ) {
      const sendingDataToServer = new Promise((resolve, reject) => {
        axios
          .post("user_password_reset", {
            user_id: user_id,
            current_password: currentPassword,
            new_password: newPassword,
            new_password_confirmation: confirmNewPassword,
          })
          .then((response) => {
            let status = response.data.success;
            if (status) {
              let successMessage = "Password updated successfully";
              resolve(successMessage);
              //  Empty field
              setCurrentPassword("");
              setNewPassword("");
              setConfirmNewPassword("");
              navigate("/");
            } else {
              let errorMessage = response.data.error;
              reject(errorMessage);
            }
          })
          .catch(function (error) {
            console.log(error); // handle error
            reject(error);
          });
      });
      // sending data to server
      toast.promise(sendingDataToServer, {
        pending: "Changing Password",
        success: {
          render({ data }) {
            return data;
          },
        },
        error: {
          render({ data }) {
            return data;
          },
        },
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <div className="ts-profile">
        <Container>
          <Row>
            <Col lg={4} className="text-center mb-08 text-lg-start mb-lg-0">
              <img
                width="353"
                className="mw-100 mb-07"
                src={ProfilePicture}
                alt="user profile"
              />
              <h2 className="text-gray-05 mb-02">{props.userInfo.name}</h2>
              <p className="heading-4 text-gray-05 mb-02 text-break">
                {props.userInfo.email}
              </p>
            </Col>
            <Col lg={8}>
              <Form noValidate validated={validated} onSubmit={resetPassword}>
                <h2 className="text-gray-05 mb-07 fw-normal">
                  {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
                </h2>

                <Form.Group className="mb-07" controlId="OldPassword">
                  <Form.Label className="heading-6 text-gray-05">
                    {props.rtlSwitch
                      ? pageData.old_password_ar
                      : pageData.old_password_en}
                  </Form.Label>
                  <Form.Control
                    type="password"
                    required
                    value={currentPassword}
                    onChange={(e) => {
                      setCurrentPassword(e.target.value); // return input value to parent
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-07" controlId="NewPassword">
                  <Form.Label className="heading-6 text-gray-05">
                    {props.rtlSwitch
                      ? pageData.new_password_ar
                      : pageData.new_password_en}
                  </Form.Label>
                  <Form.Control
                    type="password"
                    required
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value); // return input value to parent
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-07" controlId="ConfirmPassword">
                  <Form.Label className="heading-6 text-gray-05">
                    {props.rtlSwitch
                      ? pageData.confirm_password_ar
                      : pageData.confirm_password_en}
                  </Form.Label>
                  <Form.Control
                    type="password"
                    required
                    value={confirmNewPassword}
                    onChange={(e) => {
                      setConfirmNewPassword(e.target.value); // return input value to parent
                    }}
                  />
                  {newPassword === confirmNewPassword ? (
                    ""
                  ) : (
                    <p className="text-danger mt-03">
                      New password and confirmation password must be same
                    </p>
                  )}
                  {newPassword.length > 5 && confirmNewPassword.length > 5 ? (
                    ""
                  ) : (
                    <p className="text-danger mt-03">
                      New password and confirmation password must be greater
                      then 6 character
                    </p>
                  )}
                </Form.Group>

                <Button
                  className={`btn btn-primary-04  ${
                    newPassword === confirmNewPassword &&
                    newPassword.length > 5 &&
                    confirmNewPassword.length > 5
                      ? ""
                      : "ts-disabled"
                  } `}
                  disabled={
                    newPassword === confirmNewPassword &&
                    newPassword.length > 5 &&
                    confirmNewPassword.length > 5
                      ? false
                      : true
                  }
                  type="submit"
                >
                  {props.rtlSwitch
                    ? pageData.save_changes_ar
                    : pageData.save_changes_en}
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
