import "./quiz.scss";
import React, { useEffect, useState } from "react";
import axios from "helpers/AxiosConfig";
import QuizQuestion from "./QuizQuestion";
import { Container, Stack, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
export default function Quiz(props) {
  const [chapterQuiz, setChapterQuiz] = useState([]);
  const [chapterQuizQuestions, setChapterQuizQuestions] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [correctAnswer, setCorrectAnswer] = useState(0);
  const [totalQuestion, setTotalQuestion] = useState(0);

  let { courseId, chapterId } = useParams();

  useEffect(() => {
    axios
      .get(`quiz`, {
        params: { chapter_id: chapterId },
      })
      .then(function (response) {
        let questions = response.data.data[0].questions;
        setChapterQuiz(response.data.data[0]);
        setChapterQuizQuestions(questions);
        setTotalQuestion(questions.length);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  }, [courseId, chapterId]);

  function allAreTrue(arr) {
    return arr.every((element) => element === true);
  }

  const submitQuiz = () => {
    setIsSubmitted(true);
    let totalCorrections = 0;
    let descriptiveQuestions = document.querySelectorAll(
      ".ts-descriptive-question"
    );

    // Descriptive question
    for (let descriptiveQuestion of descriptiveQuestions) {
      // if descriptive question is not empty
      if (!descriptiveQuestion.value == "") {
        totalCorrections++;
      }
    }

    for (let question of chapterQuizQuestions) {
      // Boolean Question
      if (question.type === "boolean") {
        let trueFalseQuestion = document.getElementById(`id-${question.id}`);
        if (trueFalseQuestion.checked) {
          totalCorrections++;
        }
      }
      // Multiple Question
      if (question.type === "multiple") {
        let multipleChoiceQuestion = document.querySelector(
          `.ts-correct-ans-${question.id} .form-check-input`
        );
        if (multipleChoiceQuestion.checked) {
          totalCorrections++;
        }
      }
      // Checkboxes question
      if (question.type === "check") {
        let multipleChoiceQuestionsCorrect = document.querySelectorAll(
          `.ts-correct-ans-${question.id} .form-check-input`
        );
        let tsMultipleChoiceQuestion = document.querySelectorAll(
          `.ts-multiple-choice-question-${question.id} .ts-ans `
        );

        let multipleChoiceQuestionsAllChecksFalse = [];
        let multipleChoiceQuestionsAllChecksTrue = [];

        for (let multipleChoiceQuestionCorrect of multipleChoiceQuestionsCorrect) {
          if (multipleChoiceQuestionCorrect.checked) {
            multipleChoiceQuestionsAllChecksTrue.push(true);
          } else {
            multipleChoiceQuestionsAllChecksTrue.push(false);
          }
        }

        // false Ans if checked then make ans false
        for (let multipleChoiceQuestion of tsMultipleChoiceQuestion) {
          if (!multipleChoiceQuestion.classList.contains("ts-correct-ans-39")) {
            let falseAnsElements =
              multipleChoiceQuestion.querySelector(".form-check-input");
            if (falseAnsElements.checked) {
              multipleChoiceQuestionsAllChecksFalse.push(false);
            }
          }
        }

        if (
          allAreTrue(multipleChoiceQuestionsAllChecksTrue) &&
          multipleChoiceQuestionsAllChecksFalse.length === 0
        ) {
          totalCorrections++;
        }
      }
    }

    setCorrectAnswer(totalCorrections);
  };

  return (
    <div
      className={`ts-quiz-section py-4 ${
        isSubmitted ? "ts-quiz-submitted" : ""
      }`}
    >
      <Container>
        <h3 className="text-center text-uppercase mb-06">
          {props.rtlSwitch ? chapterQuiz.name_ar : chapterQuiz.name_en}
        </h3>
        {chapterQuizQuestions.map((question, index) => {
          return (
            <QuizQuestion
              rtlSwitch={props.rtlSwitch}
              key={index}
              question={question}
            />
          );
        })}

        <Stack
          className="justify-content-center gap-3 mb-07"
          direction="horizontal"
        >
          {isSubmitted ? (
            <h6>
              {correctAnswer}/ {totalQuestion}
            </h6>
          ) : (
            ""
          )}
        </Stack>
        <Stack className="justify-content-center gap-3" direction="horizontal">
          <Link
            to={`/course/${courseId}/chapter/${chapterId}`}
            className="btn btn-primary-07"
          >
            {props.rtlSwitch ? "الرجوع" : "Back"}
          </Link>
          <Button variant="primary-07" onClick={submitQuiz}>
            {props.rtlSwitch ? "عرض الإجابة" : "Submit"}
          </Button>
        </Stack>
      </Container>
    </div>
  );
}
