import React from "react";
import { Link } from "react-router-dom";

// data
import localData from "data/data.json";
let pageData = localData.cart_empty;
export default function CartEmpty(props) {
  return (
    <div className="ts-cart-container ts-rounded ts-rounded--not-bottom-right">
      <div className="ts-cart__content">
        <img
          className="mb-10"
          width="233"
          src="assets/icons/shopping-cart.svg"
          alt="shopping cart icon"
        />
        <h2 className="text-primary-06 mb-14">
          {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
        </h2>

        <Link
          className="btn btn-primary-02 btn-lg btn--border-white shadow-sm rounded-pill w-100 fw-bold"
          to={props.isLogged ? "/home" : "/"}
        >
          {props.rtlSwitch ? pageData.button_ar : pageData.button_en}
        </Link>
      </div>
    </div>
  );
}
