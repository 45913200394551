import "./App.scss";
import React, { useState, useEffect } from "react";
// Axios
import axios from "./helpers/AxiosConfig";

// Components
import Header from "components/Navigation/Header";
import PromoGiftHeader from "components/Navigation/PromoGiftHeader";
import Footer from "components/Footer/Footer";
import PromoGiftFooter from "components/Footer/PromoGiftFooter";
import SideNav from "components/Navigation/SideNav";

// pages
// import Links from "pages/Links/Links"; // temp
import Home from "pages/Home/Home";
import HomeNonRegisterUser from "pages/Home/HomeGeneral";
import Home1 from "pages/Home/Home1";
import Login from "pages/LoginAndRegister/Login";
import Register from "pages/LoginAndRegister/Register";
import ResetPassword from "pages/ResetPassword/ResetPassword";
import About from "pages/About/About";
import Cart from "pages/Cart/Cart";
import Faq from "pages/Faq/Faq";
import LegalFaq from "pages/Faq/LegalFaq";
import ProgramsMenu from "pages/ProgramMenu/ProgramMenu";
import ProgramsMenu1 from "pages/ProgramMenu/ProgramMenu1";
import ProgramDetails from "pages/ProgramDetails/ProgramDetails";
import ProgramDetailsAffiliate from "pages/ProgramDetails/ProgramDetailsAffiliate";
// import CourseDetail from "pages/CourseDetail/CourseDetail";
import TechnicalSupport from "pages/TechnicalSupport/TechnicalSupport";
import Profile from "pages/Profile/Profile";
import ForgotPassword from "pages/LoginAndRegister/ForgotPassword";
import ForgotPasswordReset from "pages/LoginAndRegister/ForgotPasswordReset";
import PaymentMethod from "pages/PaymentMethod/PaymentMethod";
import BonusMaterial from "pages/BonusMaterial/BonusMaterial";
import BonusMaterials from "pages/BonusMaterial/BonusMaterials";

import MyPrograms from "pages/Programs/Programs";
import LessonGeneral from "pages/LessonGeneral/LessonGeneral";

import PageNotFound from "pages/404/PageNotFound";
import GiftPage from "pages/GiftPage/GiftPage";
import Promo from "pages/Promo/Promo";
// logged in pages
import GetStarted from "pages/GetStarted/GetStarted";
import Course from "pages/Course/Course";
import Chapter from "pages/Chapter/Chapter";
import InspiringStories from "pages/InspiringStories/InspiringStories";
import NotificationPage from "pages/Notifications/Notifications";
import SuggestedCourses from "pages/SuggestedCourses/SuggestedCourses";
import WishList from "pages/WishList/WishList";
import UploadInspiringStories from "pages/InspiringStories/UploadInspiringStories";
import TsCalendar from "pages/TsCalendar/TsCalendar";
import PurchaseHistory from "pages/PurchaseHistory/PurchaseHistory";
import Quiz from "pages/Quiz/Quiz";

import {
  lessonComplete,
  lessonUnComplete,
} from "./common/js/lessonCompleteToggle";

// Testing
import Diva from "helpers/Redirect";

// import ProgramDetailsNew from "pages/ProgramDetailsNew/ProgramDetails";

// Payment and cart
import PaymentInVoice from "pages/PaymentInVoice/PaymentInVoice";
// // Force Render
// import useForceUpdate from "./helpers/ForceRender";
// Toast
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Function
import ScrollToTop from "./helpers/ScrollToTop";

import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
// import tr from "date-fns/esm/locale/tr/index.js";

// data
import localData from "data/data.json";
window.isLogged = true;

function App() {
  // RTL Switch
  const [rtlSwitch, setRtlSwitch] = useState(
    JSON.parse(localStorage.getItem("isRtl"))
  );
  // show logout modal
  const [isLogged, setisLogged] = useState(localStorage.getItem("is-logged"));
  const [userInfo, setUserInfo] = useState({});
  // const [toggleSideNav, setToggleSideNav] = useState();
  const [toggleSideNav, setToggleSideNav] = useState(false);
  const [promoGiftPages, setPromoGiftPages] = useState();
  const [isAdmin, setIsAdmin] = useState(true);

  // Global Data
  const [cartData, setCartData] = useState([]);
  const [notificationsList, setNotificationsList] = useState([]);
  const [myCourses, setMyCourses] = useState([]);
  const [chapterProgress, setChapterProgress] = useState("0");
  const [courseProgress, setCourseProgress] = useState("0");

  //
  // const [courseId, setCourseId] = useState(false);
  const [chapterId, setChapterId] = useState(false);
  const [courseId, setCourseId] = useState(false);

  // Ehtsham's Logic
  const [onProgramDetailsPage, setOnProgramDetailsPage] = useState(false);

  // React router
  const navigate = useNavigate();
  const location = useLocation(); // get the website location

  useEffect(() => {
    if (localStorage.getItem("is-logged") === "true") {
      setisLogged(true);
    } else {
      setisLogged(false);
    }
  }, [location]);

  useEffect(() => {
    if (localStorage.getItem("isRtl") === null) {
      localStorage.setItem("isRtl", JSON.stringify(true));
      setRtlSwitch(true);
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    if (isLogged) {
      if (
        localStorage.getItem("user-token") === undefined ||
        localStorage.getItem("user-token") === null ||
        localStorage.getItem("user-info") === undefined ||
        localStorage.getItem("user-info") === null
      ) {
        localStorage.clear(); //WARNING!: This will clear all your local storage
      }
    }
  }, []);

  useEffect(() => {
    setToggleSideNav(areWeOnThosePages(hideNavbarOnPages));
    setPromoGiftPages(areWeOnThosePages(PromoGiftPages));
    getNotification();
    getCartData();
    // CheckIsAdmin();
  }, [rtlSwitch, location.pathname]);
  useEffect(() => {
    getMyCourses();
    getAllCourses();
    CheckIsAdmin();
  }, [isLogged]);

  //Temp======================================
  setTimeout(function () {
    // getting user info from local storage and updating states
    let userInfoLocal = JSON.parse(localStorage.getItem("user-info"));
    setUserInfo(userInfoLocal);
  }, 0.5); //wait 500ms seconds
  // User logout function
  let logoutUser = () => {
    setisLogged(false);
    setUserInfo({});
    // Remove data from local storage
    let keysToRemove = ["user-token", "isAdmin", "user-info", "is-logged"];
    for (let key of keysToRemove) {
      localStorage.removeItem(key);
    }
    window.isLogged = false;
    setCartData([]); // empty cart
  };
  // Rtl
  let rtlSwitchFunc = () => {
    //  getting last Rtl value from local storage
    let lastRtlVal = JSON.parse(localStorage.getItem("isRtl"));
    localStorage.setItem("isRtl", JSON.stringify(!lastRtlVal)); // updateding local storage

    window.location.reload();
  };
  // Check the use is admin or not
  const CheckIsAdmin = () => {
    axios
      .get("isAdmin")
      .then((response) => {
        setIsAdmin(response.data.isAdmin);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };
  // Fetching Notification Data
  const getNotification = () => {
    axios
      .get("notifications")
      .then((response) => {
        setNotificationsList(response.data.data);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };
  // Fetching Cart from Api
  const getCartData = () => {
    axios
      .get("get_cart")
      .then(function (response) {
        const data = response.data.data;
        setCartData(data);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };

  const addToCartMultipleCourses = (coursesIdList) => {
    for (let courseId of coursesIdList) {
      addToCart(courseId, true);
    }
  };
  const addToCart = (courseId, isRedirectToCart) => {
    axios
      .post("add_to_cart", {
        course_id: courseId,
      })
      .then((response) => {
        getCartData(); // update cart data after element Add to cart
        let message = response.data.message;
        let courseName;
        if (rtlSwitch) {
          courseName = response.data.course.name_ar;
        } else {
          courseName = response.data.course.name_en;
        }

        if (response.data.success) {
          toast.success(`${courseName} ${message}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          if (isRedirectToCart) {
            navigate("/cart");
          }
        } else {
          toast.error(`${courseName} ${message}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };
  const removeFromCart = (courseId) => {
    axios
      .get("remove_from_cart", {
        params: {
          course_id: courseId,
        },
      })
      .then((response) => {
        getCartData(); // update cart data after element remove from car
        let message = "Item removed from Cart";
        toast.success(message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };

  // Remove all cart items
  const removeAllItemsFromCart = (courseIdArr) => {
    axios
      .get("remove_all_from_cart", {
        params: {
          ids: courseIdArr,
        },
      })
      .then((response) => {
        getCartData(); // update cart data after element remove from car
        let message = "Item removed from Cart";
        toast.success(message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };

  // Fetching My courses from Api
  const getMyCourses = async () => {
    axios
      .get("my_courses")
      .then(function (response) {
        let myCourses = response.data.data;
        setMyCourses(myCourses);
        // setPrograms(response.data.);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };

  const getAllCourses = async () => {
    axios
      .get("programs")
      .then(function (response) {
        // setAllCourses(response.data.programs);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };

  // Add ProgramDetails Path
  const hideNavbarOnPages = [
    "cart",
    "gift",
    "feminine",
    "payment-invoice",
    "/program-detail/5",
  ];
  const PromoGiftPages = ["gift", "feminine"];
  // Check if we're on specific page or not
  let areWeOnThosePages = (list) => {
    let isLinkAvailable = 0;
    list.forEach(function (pathLink) {
      isLinkAvailable += location.pathname.includes(pathLink);
    });
    if (isLinkAvailable > 0) {
      return true;
    } else {
      return false;
    }
  };

  let getChapterPercentage = (chapterId) => {
    axios
      .post("chapter_percentage", {
        chapter_id: chapterId,
      })
      .then((response) => {
        let percentage = response.data.percentage;
        setChapterProgress(percentage);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };
  let getCoursePercentage = (courseId) => {
    axios
      .post("course_percentage", {
        course_id: courseId,
      })
      .then((response) => {
        let percentage = response.data.percentage;
        setCourseProgress(percentage);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };

  const toggleCompletion = (e) => {
    let lessonId = e.target.getAttribute("data-lesson-id");
    let isChecked = e.target.checked;
    if (isChecked) {
      lessonComplete(lessonId);
    } else {
      lessonUnComplete(lessonId);
    }
    getMyCourses();
    getChapterPercentage(chapterId);
  };

  const updateChapterId = (chapterId) => {
    setChapterId(chapterId);
    getChapterPercentage(chapterId);
  };
  const updateCourseId = (courseId) => {
    setCourseId(courseId);
    getCoursePercentage(courseId);
  };

  // Hide sidebar from admin program details view page
  useEffect(() => {
    const isProgramDetailsPage = location.pathname.includes("/program-detail/");
    setToggleSideNav(isProgramDetailsPage);
    setOnProgramDetailsPage(isProgramDetailsPage);
  }, [location.pathname]);

  useEffect(() => {
    console.log("ON PROGRAM DETAILS PAGE .... ", onProgramDetailsPage);
  }, [onProgramDetailsPage]);

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={rtlSwitch}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div
        className={`App ${isLogged && !toggleSideNav ? "ts-logged-in" : ""} ${rtlSwitch ? "ts-rtl" : ""
          }  `}
        dir={`${rtlSwitch ? "rtl" : ""}`}
      >
        <ScrollToTop />

        {promoGiftPages ? (
          <PromoGiftHeader />
        ) : (
          <Header
            UserInfo={userInfo}
            LogoutUser={logoutUser}
            isLogged={isLogged}
            rtlSwitchFunc={rtlSwitchFunc}
            rtlSwitch={rtlSwitch}
            ProductNumber={cartData?.length}
            NotificationsNumber={notificationsList?.length}
            onProgramDetailsPage={onProgramDetailsPage}
          />
        )}
        {isLogged && !toggleSideNav && (
          <SideNav
            rtlSwitch={rtlSwitch}
            isAdmin={isAdmin}
            myCourses={myCourses}
            chapterProgress={chapterProgress}
            courseProgress={courseProgress}
          // getChapterPercentage={getChapterPercentage}
          />
        )}

        <div
          className={`ts-main mh-body-height-main ${!promoGiftPages && isLogged ? "" : "ts-padding-top-negative-header"
            }`}
        >
          {/* <div className={`ts-main`}> */}
          <Routes>
            <Route
              path="*"
              element={<PageNotFound rtlSwitch={rtlSwitch} />}
            ></Route>

            <Route
              path="/"
              element={
                <HomeNonRegisterUser
                  rtlSwitch={rtlSwitch}
                  isLogged={isLogged}
                />
              }
            ></Route>
            <Route
              path="/home"
              element={<Home rtlSwitch={rtlSwitch} isLogged={isLogged} />}
            ></Route>
            <Route
              path="/login"
              element={
                <Login
                  addToCartMultipleCourses={addToCartMultipleCourses}
                  toggleLoginData={(state, userinfo) => {
                    setisLogged(state);
                    setUserInfo(userinfo);
                  }}
                  rtlSwitch={rtlSwitch}
                />
              }
            ></Route>
            <Route
              path="/register"
              element={<Register rtlSwitch={rtlSwitch} />}
            ></Route>
            <Route
              path="/forget-password"
              element={<ForgotPassword rtlSwitch={rtlSwitch} />}
            ></Route>
            <Route
              path="/forget-password-reset/:email/:token"
              element={<ForgotPasswordReset rtlSwitch={rtlSwitch} />}
            ></Route>
            <Route
              path="/reset-password"
              element={<ResetPassword userInfo={userInfo} />}
            ></Route>

            <Route path="/home-1" element={<Home1 />}></Route>
            <Route path="/login-cart" element={<Login />}></Route>

            <Route
              path="/about"
              element={<About rtlSwitch={rtlSwitch} />}
            ></Route>
            <Route
              path="/cart"
              element={
                <Cart
                  cartData={cartData}
                  rtlSwitch={rtlSwitch}
                  removeFromCart={removeFromCart}
                />
              }
            ></Route>
            <Route
              path="/payment-invoice"
              element={
                <PaymentInVoice userInfo={userInfo} rtlSwitch={rtlSwitch} />
              }
            ></Route>
            <Route path="/faq" element={<Faq rtlSwitch={rtlSwitch} />}></Route>
            <Route
              path="/calendar"
              element={<TsCalendar rtlSwitch={rtlSwitch} />}
            ></Route>

            <Route
              path="/course/:courseNumber"
              element={
                <Course
                  rtlSwitch={rtlSwitch}
                  myCourses={myCourses}
                  updateCourseId={updateCourseId}
                />
              }
            ></Route>
            <Route
              path="/course/:courseNumber/get-started"
              element={<GetStarted rtlSwitch={rtlSwitch} isAdmin={isAdmin} />}
            ></Route>
            <Route
              path="/course/:courseNumber/chapter/:chapterNumber"
              element={
                <Chapter
                  localData={localData}
                  rtlSwitch={rtlSwitch}
                  myCourses={myCourses}
                  toggleCompletion={toggleCompletion}
                  updateChapterId={updateChapterId}
                />
              }
            ></Route>
            <Route
              path="/course/:courseNumber/chapter/:chapterNumber/lesson-general/:lessonNumber"
              element={
                <LessonGeneral
                  rtlSwitch={rtlSwitch}
                  isAdmin={isAdmin}
                  myCourses={myCourses}
                  toggleCompletion={toggleCompletion}
                  updateChapterId={updateChapterId}
                />
              }
            ></Route>

            <Route
              path="/legal-faq"
              element={<LegalFaq rtlSwitch={rtlSwitch} />}
            ></Route>
            {/* <Route
              path="/course-detail/*"
              element={
                <CourseDetail
                  rtlSwitch={rtlSwitch}
                  addToCartMultipleCourses={addToCartMultipleCourses}
                />
              }
            ></Route> */}

            <Route path="/program-menu/*" element={<ProgramsMenu />}></Route>
            <Route path="/program-menu-1" element={<ProgramsMenu1 />}></Route>

            <Route
              path="/program-detail/:ProgramId"
              element={
                <ProgramDetails
                  rtlSwitch={rtlSwitch}
                  addToCart={addToCart}
                  addToCartMultipleCourses={addToCartMultipleCourses}
                />
              }
            ></Route>
            <Route
              path="/program-detail/:ProgramId/:AffiliateCode"
              element={
                <ProgramDetailsAffiliate
                  rtlSwitch={rtlSwitch}
                  addToCart={addToCart}
                  addToCartMultipleCourses={addToCartMultipleCourses}
                />
              }
            ></Route>

            <Route
              path="/profile"
              element={<Profile rtlSwitch={rtlSwitch} />}
            ></Route>
            <Route
              path="/payment-method"
              element={<PaymentMethod rtlSwitch={rtlSwitch} />}
            ></Route>
            <Route
              path="/course/:courseNumber/bonus-materials"
              element={<BonusMaterials rtlSwitch={rtlSwitch} />}
            ></Route>
            <Route
              path="/course/:courseNumber/bonus-material/:bonusMaterialId"
              element={
                <BonusMaterial rtlSwitch={rtlSwitch} isAdmin={isAdmin} />
              }
            ></Route>
            <Route
              path="/wishlist"
              element={<WishList rtlSwitch={rtlSwitch} addToCart={addToCart} />}
            ></Route>
            <Route
              path="/suggested-courses"
              element={<SuggestedCourses rtlSwitch={rtlSwitch} />}
            ></Route>

            <Route
              path="/inspiring-stories"
              element={<InspiringStories rtlSwitch={rtlSwitch} />}
            ></Route>
            <Route
              path="/upload-story"
              element={<UploadInspiringStories rtlSwitch={rtlSwitch} />}
            ></Route>

            <Route
              path="/technical-support"
              element={<TechnicalSupport rtlSwitch={rtlSwitch} />}
            ></Route>
            <Route
              path="/notification"
              element={
                <NotificationPage NotificationsList={notificationsList} />
              }
            ></Route>

            <Route
              path="/gift/:pageId"
              element={<GiftPage rtlSwitch={rtlSwitch} />}
            ></Route>
            <Route
              path="/feminine"
              element={<Promo rtlSwitch={rtlSwitch} />}
            ></Route>
            {/* testing */}
            <Route path="/diva" element={<Diva />}></Route>
            {/* testing */}
            <Route
              path="/"
              element={
                <HomeNonRegisterUser
                  rtlSwitch={rtlSwitch}
                  isLogged={isLogged}
                />
              }
            ></Route>

            {/* Page only for login user Start*/}
            {isLogged ? (
              <>
                <Route
                  path="/my-programs"
                  element={
                    <MyPrograms
                      rtlSwitch={rtlSwitch}
                      isAdmin={isAdmin}
                      myCourses={myCourses}
                    />
                  }
                ></Route>
                <Route
                  path="course/:courseId/chapter/:chapterId/quiz"
                  element={<Quiz rtlSwitch={rtlSwitch} />}
                ></Route>
                <Route
                  path="/purchase-history"
                  element={<PurchaseHistory rtlSwitch={rtlSwitch} />}
                ></Route>
              </>
            ) : (
              ""
            )}

            {/* Page only for login user End*/}
          </Routes>
        </div>
      </div>

      {promoGiftPages ? (
        <PromoGiftFooter />
      ) : (
        <Footer isLogged={isLogged} rtlSwitch={rtlSwitch} onProgramDetailsPage={onProgramDetailsPage} />
      )}
    </React.Fragment>
  );
}

export default App;
