
import React from "react";


import PageNotFountContent from "./PageNotFountContent";

export default function PageNotFound(props) {
  return (
    <section className="ts-page-content page_404">
      <div className="container">
        <PageNotFountContent rtlSwitch={props.rtlSwitch} />
      </div>
    </section>
  );
}
