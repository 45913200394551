import React from "react";

import Container from "react-bootstrap/Container";

import SuggestedCourseCardGroup from "components/Courses/SuggestedCoursesCardsGroup";

// data
import localData from "data/data.json";
let pageData = localData.suggested_courses;

export default function SuggestedCourses(props) {
  return (
    <section className="ts-page-content">
      <Container>
        <h2 className="mb-10">
          {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
        </h2>
        <SuggestedCourseCardGroup rtlSwitch={props.rtlSwitch} />
      </Container>
    </section>
  );
}
