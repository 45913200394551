import "./vimeo.scss";
import React, { useEffect } from "react";

// import Player from "@vimeo/player";
// import VimeoPlayer from "@vimeo/player";

export default function Vimeo(props) {
  const videoId = props.Url.split("/").pop();

  useEffect(() => {
    // setTimeout(() => {
    //   let duration = document.getElementsByName("timecode");
    //   console.log("duration : " + duration);
    //   console.log(ReactDOM.findDOMNode("timecode").innerHTML);
    // }, 1000);
    // const iframe = document.getElementsByName("ts-vimeo-video");
    // const player = new Vimeo.Player(iframe);
    // player
    //   .getDuration()
    //   .then(function (duration) {
    //     // duration = the duration of the video in seconds
    //     console.log("duration : " + duration);
    //   })
    //   .catch(function (error) {
    //     // an error occurred
    //   });
  }, []);

  return (
    <div className={`ts-vimeo-video-container ${props.addClassName}`}>
      <iframe
        className="ts-vimeo-video"
        title={props.Title}
        src={`https://player.vimeo.com/video/${videoId}?h=3168f6aec0&title=0&byline=0&portrait=0`}
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
}
