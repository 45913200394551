import React, { useEffect, useState } from "react";

import axios from "helpers/AxiosConfig";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function PostComment(props) {
  const [commentCharCount, setCommentCharCount] = useState(0);
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  // Post comment
  let postComment = () => {
    axios
      .post(`add_page_comment`, {
        page_name: "feminene",
        visitor_name: name,
        visitor_email: email,
        comment: comment,
      })
      .then(function (response) {
        let message = response.data.message;
        toast.success(message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setName("");
        setEmail("");
        setComment("");
        setCommentCharCount(0);
        props.refreshComments();
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Form className="ts-review-post mb-04">
        <Stack direction="horizontal" className="justify-content-between mb-06">
          <Form.Label className="heading-1">Comments</Form.Label>
          <h1>{commentCharCount}</h1>
        </Stack>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control
            type="text"
            value={name}
            className="py-3"
            placeholder="Enter Name"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control
            type="email"
            value={email}
            className="py-3"
            placeholder="Enter email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Form.Group>

        <Form.Group className=" mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Control
            as="textarea"
            rows={4}
            value={comment}
            placeholder="Comments"
            onChange={(e) => {
              setComment(e.target.value);
              setCommentCharCount(e.target.value.length);
            }}
          />
        </Form.Group>

        <div className="text-end">
          <Button
            variant="primary-01"
            className="btn btn--border-white btn-lg px-5 w-auto "
            onClick={() => postComment()}
          >
            Comment
          </Button>
        </div>
      </Form>
    </>
  );
}
