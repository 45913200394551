import React, { useEffect, useState } from "react";
import axios from "helpers/AxiosConfig";
import "./courses.scss";

import SuggestedCourseCard from "./SuggestedCourseCard";

// Toaste
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function CoursesGroup1(props) {
  const [suggestedCourses, setSuggestedCourses] = useState([]);

  useEffect(() => {
    axios
      .get("/suggested_courses", {
        params: {
          course_id: 1,
        },
      })
      .then((response) => {
        setSuggestedCourses(response.data.suggested_courses);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  }, []);

  // Add course to wishlist
  let addCourseToWishList = (courseId) => {
    axios
      .get("add_to_wishlist", {
        params: {
          course_id: courseId,
        },
      })
      .then((response) => {
        let message = "Course added to wishlist";
        toast.success(message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        className={
          "ts-courses-group " +
          (props.groupCol === "2" ? "ts-courses-group--2-cards" : "")
        }
      >
        {suggestedCourses.map((course, index) => {
          return (
            <SuggestedCourseCard
              key={index}
              Id={course.id}
              CourseTitle={props.rtlSwitch ? course.name_ar : course.name_en}
              CourseDesc={
                props.rtlSwitch ? course.description_ar : course.description_en
              }
              CourseBtnName={
                props.rtlSwitch ? " تفاصيل الدورة" : "Course Detail"
              }
              addCourseToWishList={addCourseToWishList}
            />
          );
        })}
      </div>
    </>
  );
}
