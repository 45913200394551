import "./home.scss";
import React, { useEffect, useState } from "react";
import axios from "helpers/AxiosConfig";
import Container from "react-bootstrap/Container";

import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import Programs from "components/Courses/Programs";
import Courses2 from "components/Courses/CoursesGroup2";
import SubscribeEmail from "components/SubscribeEmail/SubscribeEmail";

import VideoPoster from "../../assets/images/video-poster.png";

export default function Home1(props) {
  const [programs, setPrograms] = useState([]);
  // const [homeData, setHomeData] = useState([]);

  useEffect(() => {
    axios
      .get("programs")
      .then(function (response) {
        setPrograms(response.data.programs);
        // console.log(JSON.stringify(response.data.programs));
        // console.log(JSON.stringify(programs));
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
    //   axios
    //     .get("homepage")
    //     .then(function (response) {
    //       setHomeData(response.data.data);
    //       // console.log(JSON.stringify(response.data.data.landing_page_video));
    //       console.log(homeData);
    //       console.log(homeData.landing_page_video);
    //     })
    //     .catch(function (error) {
    //       console.log(error); // handle error
    //     });
  }, []);

  return (
    <>
      <section className="ts-hero">
        <Container>
          <VideoPlayer
            // src={homeData.landing_page_video}
            src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
            poster={VideoPoster}
          />
        </Container>
      </section>
      <section className="ts-reham-element ts-section-py">
        <Container>
          {/* <Programs CoursesTitle="Reham Elements" ProgramsList={programs} /> */}
        </Container>
      </section>
      <section className="ts-reham-element ts-section-py">
        <Container>
          <Courses2 CoursesTitle="Upcoming Events" />
        </Container>
      </section>
      <section className="ts-stay-intouch--single ts-section-py w-100">
        <Container>
          <SubscribeEmail />
        </Container>
      </section>
    </>
  );
}
