import "./promo.scss";
import React, { useState, useEffect } from "react";

// Swiper
import SwiperCore, { Virtual, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Bootstrap
import { Container, Stack } from "react-bootstrap";

import CustomForm from "../GiftPage/CustomForm";

import axios from "helpers/AxiosConfig";
// Components
import ReviewsGroup from "components/Review/ReviewsGroup";

// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

import VimeoPlayer from "components/VideoPlayer/Vimeo";
// import the component
import MailchimpSubscribe from "react-mailchimp-subscribe";

const REACT_APP_MAILCHIMP_U = "f9ca257be2b890b55df3d95d4";
const REACT_APP_MAILCHIMP_ID = "e8cdc9d62e";

const postUrl = `https://rehamhouse.us9.list-manage.com/subscribe/post?u=${REACT_APP_MAILCHIMP_U}&id=${REACT_APP_MAILCHIMP_ID}`;

// install Virtual module
SwiperCore.use([Virtual, Navigation, Pagination]);

// import img from "http://localhost:3000/static/media/hero-slider-logged-in.f5295de06cecb5dfc83b.jpg";
export default function Promo(props) {
  const [swiperRef, setSwiperRef] = useState(null);
  // Commnets
  const [comments, setComments] = useState([]);
  const [commentsCount, setCommentsCount] = useState("");

  useEffect(() => {
    getComments();
  }, []);
  // Get all comments
  let getComments = (lessonNum) => {
    axios
      .get(`all_page_comments`, {
        // params: { lesson_id: lessonNum },
      })
      .then(function (response) {
        let comments = response.data.data;
        let commentsCount = response.data.total;
        setCommentsCount(commentsCount)
        setComments(comments);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };
  // Toggle like
  let ToggleLike = (commentId) => {
    axios
      .get(`like`, {
        params: { comment_id: commentId },
      })
      .then(function (response) {
        getComments(1);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });

    console.log("you've liked the comment id is : ", commentId);
  };

  const slideTo = (index) => {
    swiperRef.slideTo(index - 1, 0);
  };

  return (
    <div className="py-5">
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <Container>
        <Swiper
          onSwiper={setSwiperRef}
          slidesPerView={1}
          centeredSlides={true}
          spaceBetween={30}
          navigation={true}
          className="mb-10"
        >
          <SwiperSlide>
            <VimeoPlayer Url="https://vimeo.com/758266705" />
          </SwiperSlide>
          {/* <SwiperSlide>
            <VimeoPlayer Url="https://vimeo.com/758268384" />
          </SwiperSlide> */}
        </Swiper>

        <Stack
          direction="horizontal"
          className="justify-content-center mb-12 mw-700 mx-auto gap-3"
        >
          <button className="ts-promo-hero__btn" onClick={() => slideTo(1)}>
            <img
              width="100"
              src="./assets/images/تصميم ثمب نيل موقع الكتروني تسويق رهام ديفا ٢٠٢٢ (١).jpg"
              alt=".."
            />
          </button>
          <button className="ts-promo-hero__btn" onClick={() => slideTo(2)}>
            <img
              width="100"
              src="./assets/images/تصميم ثمب نيل موقع الكتروني تسويق رهام ديفا ٢٠٢٢ (٢).jpg"
              alt=".."
            />
          </button>
          <button className="ts-promo-hero__btn" onClick={() => slideTo(3)}>
            <img
              width="100"
              src="./assets/images/تصميم ثمب نيل موقع الكتروني تسويق رهام ديفا ٢٠٢٢ (٣).jpg"
              alt=".."
            />
          </button>
          <button className="ts-promo-hero__btn" onClick={() => slideTo(4)}>
            <img
              width="100"
              src="./assets/images/تصميم ثمب نيل موقع الكتروني تسويق رهام ديفا ٢٠٢٢ (٤).jpg"
              alt=".."
            />
          </button>
        </Stack>

        <MailchimpSubscribe
          url={postUrl}
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={(formData) => {
                // toast.success("الاشتراك ناجح", {
                //   position: "top-right",
                //   autoClose: 3000,
                //   hideProgressBar: false,
                //   closeOnClick: true,
                //   pauseOnHover: true,
                //   draggable: true,
                //   progress: undefined,
                // });
                subscribe(formData);
              }}
            />
          )}
        />

        <ReviewsGroup
          rtlSwitch={props.rtlSwitch}
          comments={comments}
          commentsCount={commentsCount}
          ToggleLike={ToggleLike}
          pages={true}
          refreshComments={() => getComments()}
        // lessonId={currentLessonNumber}
        />
      </Container>
    </div>
  );
}
