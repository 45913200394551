import React from "react";

// Bootstrap
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import VideoPlayer from "react-video-js-player";

export default function InspirignStoriesModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="ts-inspiring-stories-modal"
    >
      <Modal.Body>
        <button className="btn p-0 float-end" onClick={props.onHide}>
          <img width="30" src="/assets/icons/Icon-plus.svg" alt="..." />
        </button>
        <h5 className="text-center w-100">{props.story.user.name}</h5>
        <p className="text-center mb-07">{props.story.comment}</p>

        <div className="video-wrapper mx-lg-4">
          <VideoPlayer
            controls={true}
            src={props.story.file_url}
            // poster={this.state.video.poster}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
