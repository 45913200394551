import "./inspiring-story.scss";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "helpers/AxiosConfig";
// Bootstrap
import Container from "react-bootstrap/Container";
// Components
import InspiringStoriesCardGroup from "components/InspiringStories/InspiringStoriesCardGroup";
import InspirignStoriesModal from "components/InspiringStories/InspirignStoriesModal";
// data
import localData from "data/data.json";
let pageData = localData.inspiring_stories;

export default function InspiringStories(props) {
  const [successStories, setSuccessStories] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [showActiveStory, setShowActiveStory] = useState();

  useEffect(() => {
    // Fetching programs from Api
    axios
      .get("success_story")
      .then(function (response) {
        let stories = response.data.data;
        setSuccessStories(stories);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  }, []);

  let showStoryModal = (id) => {
    // Get Active story and set is to active story
    setShowActiveStory(getActiveStory(successStories, id));
    setModalShow(true);
  };

  // return the active storry accordin to Id
  function getActiveStory(array, id) {
    for (var i = 0, len = array.length; i < len; i++) {
      if (array[i].id === id) {
        return array[i];
      }
    }
    return null; // Nothing found
  }

  return (
    <section className="ts-page-content ts-inspiring-stories">
      <Container>
        <h3 className="fw-normal mb-04">
          {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
        </h3>
        <p className="ts-desc-sm mb-06">
          {props.rtlSwitch ? pageData.desc_ar : pageData.desc_en}
        </p>
        <Link className="btn btn-primary-01 mb-09" to="/upload-story">
          {props.rtlSwitch ? pageData.upload_btn_ar : pageData.upload_btn_en}
        </Link>
        <InspiringStoriesCardGroup
          stories={successStories}
          rtlSwitch={props.rtlSwitch}
          showModal={(id) => showStoryModal(id)}
        />
        {modalShow ? (
          <InspirignStoriesModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            story={showActiveStory}
          />
        ) : (
          ""
        )}
      </Container>
    </section>
  );
}
