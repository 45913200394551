import axios from "axios";
// let token = JSON.parse(localStorage.getItem("user-token"));
export default axios.create({
  // baseURL: "https://reham.mukkancom.dev/api/",
  baseURL: "https://admin.reham.com/api/",
  // headers: { Authorization: `Bearer ${token}` },
  headers: {
    'Access-Control-Allow-Origin': '*'
  },
});
