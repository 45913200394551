import React from "react";
import { Link, useParams } from "react-router-dom";

export default function Chapter(props) {
  let { courseNumber } = useParams();

  return (
    <article className="ts-chapter">
      <h4 className="ts-chapter__title">
        <Link
          className="text-gray-05 text-decoration-none"
          to={`/course/${courseNumber}/chapter/${props.data.id}`}
        >
          {props.rtlSwitch ? props.data.name_ar : props.data.name_en}
        </Link>
      </h4>

      <div className="ts-chapter__main">
        <div className="row row-cols-lg-2 gap-3 gap-lg-0">
          <div>
            <h6
              dangerouslySetInnerHTML={{
                __html: props.rtlSwitch
                  ? props.data.description_ar
                  : props.data.description_en,
              }}
            ></h6>
          </div>
          <div className="text-center">
            <div className="row row-cols-2 text-primary-07">
              <div>
                <h5> {props.rtlSwitch ? "مكتمل" : "Completed"}</h5>
                <p className="heading-4 mb-0">
                  {props.data.completion_percentage}%
                </p>
              </div>
              <div>
                <h5> {props.rtlSwitch ? "مدة" : "Duration"} </h5>
                <p className="heading-4 mb-0">
                  {props.data.duration} &nbsp;
                  {props.rtlSwitch ? "دقيقة" : "Min"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}
