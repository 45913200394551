import "./lesson-tabs.scss";
import React, { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";

import LessonTabLesson from "./LessonTabLesson";

// import {
//   getVimeoId,
//   getVomeoData,
//   getVomeoThumbnail,
// } from "get-vimeo-thumbnail";
export default function LessonsTabsData(props) {
  const [lessons, setlessons] = useState([]);

  useEffect(() => {
    let chapters = props.course.chapters;
    let allLessons = [];

    for (let chapter in chapters) {
      let chapterlessons = chapters[chapter].lessons;
      allLessons = [...allLessons, ...chapterlessons];
    }
    setlessons(allLessons);
  }, [props.course.chapters]);

  return (
    <div>
      <h3 className="mb-08 mt-3">
        {props.rtlSwitch ? props.course.name_ar : props.course.name_en}
      </h3>

      <Stack
        className="ts-lessons-group flex-wrap justify-content-center "
        direction="horizontal"
        gap={3}
      >
        {lessons.map((lesson, index) => {
          return <LessonTabLesson key={index} lesson={lesson} />;
        })}
      </Stack>
    </div>
  );
}
