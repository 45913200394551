import "./courses.scss";
import React from "react";
import { Link, useLocation } from "react-router-dom";
// import axios from "helpers/AxiosConfig";
import Stack from "react-bootstrap/Stack";

import HeartIcon from "../../assets/icons/Icon-awesome-heart.svg";
import ShoppinCart from "../../assets/icons/Icon-material-shopping-cart.svg";
import DeleteIcon from "../../assets/icons/Icon-material-delete-forever.svg";

export default function SingleCourse(props) {
  const location = useLocation(); // get the website location

  return (
    <div className="ts-course-card ts-course-card--primary">
      <Stack direction="horizontal" className="justify-content-between mb-09">
        <div>
          {location.pathname === "/wishlist" ? (
            <button
              className="btn btn-change"
              onClick={() => props.removeCourseToWishList(props.CourseId)}
            >
              <img
                className="ts-course-card__delete"
                src={DeleteIcon}
                alt="..."
              />
            </button>
          ) : (
            <button className="btn btn-change">
              <img
                className="ts-course-card__heart"
                src={HeartIcon}
                alt="..."
              />
            </button>
          )}
        </div>

        <h6 className="ts-course-card__title mb-0">{props.CourseTitle}</h6>
        <div>
          <button
            className="btn btn-change"
            onClick={() => props.addToCart(props.CourseId)}
          >
            <img className="ts-course-card__cart" src={ShoppinCart} alt="..." />
          </button>
        </div>
      </Stack>
      <div className="w-100">
        <p
          className="ts-desc-xs mb-01 fw-normal mb-05"
          dangerouslySetInnerHTML={{ __html: props.CourseDesc }}
        ></p>

        <Link
          className="ts-course-card__btn btn"
          to={`/course-detail/${props.CourseId}`}
        >
          {props.CourseBtn}
        </Link>
      </div>
    </div>
  );
}
