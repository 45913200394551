import "./review.scss";
import React from "react";

import SingleReview from "./SingleReview";
import SingleReviewPages from "./SingleReviewPages";
// bootstrap
import Stack from "react-bootstrap/Stack";

import PostComment from "./PostComment";
import PostCommentPages from "./PostCommentPages";

// data
import localData from "data/data.json";
let pageData = localData.comments;

export default function ReviewsGroup(props) {
  return (
    <div>
      {props.pages ? (
        <PostCommentPages
          refreshComments={() => {
            props.refreshComments();
          }}
        />
      ) : (
        <PostComment
          rtlSwitch={props.rtlSwitch}
          lessonId={props.lessonId}
          courseId={props.courseId}
          bonusMaterialId={props.bonusMaterialId}
        />
      )}

      <Stack className="justify-content-between mb-09 ms-lg-4 " direction="horizontal">
        <h1 className="text-gray-05 ">
          {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
        </h1>
        <h1 className="text-gray-05 ">
          {props.commentsCount}
        </h1>
      </Stack>

      <Stack gap={4}>
        {props.comments.map((comment, index) => {
          return (
            <article className="ts-review " key={index}>
              {props.pages ? (
                <SingleReviewPages
                  rtlSwitch={props.rtlSwitch}
                  comment={comment}
                  ToggleLike={props.ToggleLike}
                  isAdmin={props.isAdmin}
                />
              ) : (
                <SingleReview
                  rtlSwitch={props.rtlSwitch}
                  comment={comment}
                  ToggleLike={props.ToggleLike}
                  isAdmin={props.isAdmin}
                />
              )}
            </article>
          );
        })}
      </Stack>
    </div>
  );
}
