import "./home-general.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Bootstrap
import { Container, Row, Col } from "react-bootstrap";

// Components
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import Programs from "components/Courses/Programs";
import Courses2 from "components/Courses/CoursesGroup2";
import SubscribeEmail from "components/SubscribeEmail/SubscribeEmail";

import axios from "helpers/AxiosConfig";
import VideoPoster from "../../assets/images/video-poster.png";

// data
import localData from "data/data.json";
let pageData = localData.home;

export default function Home(props) {
  const [programs, setPrograms] = useState([]);
  const [homeData, setHomeData] = useState([]);
  const [specialCourses, setSpecialCourses] = useState([]);
  // const [videoUrl, setVideoUrl] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // redirect to Home page register is userr is logged in
    if (localStorage.getItem("user-info")) {
      navigate("/home");
    }

    axios
      .get("programs")
      .then(function (response) {
        setPrograms(response.data.programs);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
    axios
      .get("special_courses")
      .then(function (response) {
        setSpecialCourses(response.data.data.special_courses);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });

    axios
      .get("homepage")
      .then(function (response) {
        setHomeData(response.data.data);
        // console.log(response.data.data.landing_page_video);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  }, [props.localData]);

  return (
    <>
      <main>
        <section className="ts-hero">
          <Container>
            {homeData.landing_page_video ? (
              <VideoPlayer
                src={homeData.landing_page_video}
                poster={VideoPoster}
              />
            ) : (
              ""
            )}
          </Container>
        </section>
        <section className="ts-reham-element ts-section-py">
          <Container>
            <Programs
              CoursesTitle={
                props.rtlSwitch
                  ? pageData.program_title_ar
                  : pageData.program_title_en
              }
              ProgramsList={programs}
              rtlSwitch={props.rtlSwitch}
              isLogged={props.isLogged}
            />
          </Container>
        </section>

        {/* hide section when special offer's are not available */}
        {specialCourses.length > 0 ? (
          <section className="ts-reham-element ts-section-py">
            <Container>
              <Courses2
                CoursesTitle={
                  props.rtlSwitch
                    ? pageData.specialoffer_title_ar
                    : pageData.specialoffer_title_en
                }
                SpecialCourseslist={specialCourses}
                rtlSwitch={props.rtlSwitch}
              />
            </Container>
          </section>
        ) : (
          ""
        )}
        <section className="ts-stay-intouch ts-section-py w-100 font-family-lato">
          <Container>
            <Row className="gap-5 gap-xl-0">
              <Col sm={12} xl={6} className="d-flex align-items-center">
                {/* <img
                  className="w-100 ts-rounded ts-rounded--not-top-left"
                  src={homeData.contact_image}
                  alt="..."
                /> */}
                <div
                  className={`ts-contact-poster ts-rounded ${
                    props.rtlSwitch
                      ? "ts-rounded--not-bottom-right"
                      : "ts-rounded--not-top-left"
                  }`}
                >
                  <h3 className="text-white fw-normal">
                    {props.rtlSwitch
                      ? pageData.contact_poster_text_ar
                      : pageData.contact_poster_text_en}
                  </h3>
                </div>
              </Col>
              <Col sm={12} xl={6} className="h-auto">
                <SubscribeEmail rtlSwitch={props.rtlSwitch} />
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
}
