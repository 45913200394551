import "./courses.scss";
import React from "react";

// import axios from "helpers/AxiosConfig";

import { Link } from "react-router-dom";

import Stack from "react-bootstrap/Stack";

import HeartIcon from "../../assets/icons/Icon-awesome-heart.svg";
import ShoppinCart from "../../assets/icons/Icon-material-shopping-cart.svg";

export default function SingleCourse(props) {
  return (
    <div className="ts-course-card ts-course-card--primary ">
      <Stack direction="horizontal" className="justify-content-between mb-09">
        <button
          className="btn btn-change"
          onClick={() => props.addCourseToWishList(props.CourseId)}
        >
          <img src={HeartIcon} alt="..." />
        </button>
        <h4 className="ts-course-card__title mb-0">{props.CourseTitle}</h4>
        <button className="btn btn-change">
          <img src={ShoppinCart} alt="..." />
        </button>
      </Stack>

      <div>
        <p
          className="ts-course-card__desc ts-desc-xs mb-0"
          dangerouslySetInnerHTML={{
            __html: props.CourseDesc,
          }}
        >
          {/* {props.CourseDesc} */}
        </p>
        <p className="ts-course-card__desc ts-desc-xs mb-03">0 % Completed</p>
        <Link
          className="ts-course-card__btn btn"
          to={"/course-detail/" + props.Id}
        >
          {props.CourseBtnName}
        </Link>
      </div>
    </div>
  );
}
