import "./quality-cirtificate.scss";
import React from "react";
import { Container, Stack } from "react-bootstrap";

// data
import localData from "data/data.json";
let pageData = localData.quantity_cirtificate;

export default function QualityCirtificate(props) {
  return (
    <div className="ts-quality-cirtificate ">
      <Container>
        <Stack className="flex-lg-row align-items-center" gap={3}>
          <div>
            <img width="275" src="/assets/images/Artboard.png" alt="..." />
          </div>
          <div>
            <p className="ts-desc-lg">
              {props.rtlSwitch
                ? pageData.description_ar
                : pageData.description_en}
             {props.warranty} 
             {props.rtlSwitch
                ? pageData.description_ar_1
                : pageData.description_en}
            </p>
          </div>
        </Stack>
      </Container>
    </div>
  );
}
