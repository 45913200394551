import "./programs-menu.scss";
import React from "react";

import Container from "react-bootstrap/Container";

import ProgramMenuTitleCard from "components/ProgramMenuTitleCard/ProgramMenuTitleCard";
import CoursesGroup3 from "components/Courses/CoursesGroup3";

const Courses = [
  {
    CourseTitle: "Courses Name",
    CourseDetails: "UI UX Design Fundemantals",
  },
  {
    CourseTitle: "Courses Name",
    CourseDetails: "UI UX Design Fundemantals",
  },
  {
    CourseTitle: "Courses Name",
    CourseDetails: "UI UX Design Fundemantals",
  },
  {
    CourseTitle: "Courses Name",
    CourseDetails: "UI UX Design Fundemantals",
  },
];

export default function ProgramMenu() {
  return (
    <div className="ts-programs-menu">
      <Container>
        <div className="ts-programs-menu__container">
          <aside className="ts-programs-menu__title">
            <ProgramMenuTitleCard
              title="Program 1"
              img="/assets/icons/awesome-book-reader.svg"
            />
          </aside>
          <div className="ts-programs-menu__main">
            <CoursesGroup3 Courses={Courses} groupCol="2" />
          </div>
        </div>
        <div className="ts-programs-menu__container">
          <aside className="ts-programs-menu__title">
            <ProgramMenuTitleCard
              title="Program 2"
              img="/assets/icons/awesome-book-reader.svg"
            />
          </aside>
          <div className="ts-programs-menu__main">
            <CoursesGroup3 Courses={Courses} groupCol="2" />
          </div>
        </div>
        <div className="ts-programs-menu__container">
          <aside className="ts-programs-menu__title">
            <ProgramMenuTitleCard
              isActive="active"
              title="Program 3"
              img="/assets/icons/awesome-book-reader.svg"
            />
          </aside>
          <div className="ts-programs-menu__main">
            <CoursesGroup3 Courses={Courses} groupCol="2" />
          </div>
        </div>
      </Container>
    </div>
  );
}
