import "./login-and-register.scss";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
// Axios
import axios from "helpers/AxiosConfig";
import { Container, Form, Button } from "react-bootstrap";
import TSFormControl from "./TsFormControl";
// Toast
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// data
import localData from "data/data.json";
let pageData = localData.forgot_password;
export default function ResetPassword(props) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [validated, setValidated] = useState(false);

  let { email, token } = useParams();

  let forgetPasswordReset = (event) => {
    event.preventDefault(); // prevent default behaviour
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    axios
      .post("reset_password", {
        token: token,
        email: email,
        password: newPassword,
        password_confirmation: confirmNewPassword,
      })
      .then((response) => {
        // console.log(response);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };
  return (
    <section className="ts-login">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Container>
        <div className="ts-login__form-container  ">
          <div className="text-center">
            <img
              width="137"
              className="mb-10 mx-auto"
              src="/assets/icons/reham-diva-program.svg"
              alt="reham diva program logo"
            />
          </div>
          <div className="ts-login__form-container-inner ts-rounded ts-rounded--not-bottom-right">
            <Form
              className="ts-login__form"
              noValidate
              validated={validated}
              onSubmit={forgetPasswordReset}
            >
              <h3 className="text-primary-03 mb-11 text-center ">
                {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
              </h3>
              <Form.Group className="ts-form-group mb-3">
                <Form.Control
                  className="rounded-pill shadow-sm "
                  type="email"
                  required
                  min="6"
                  max="16"
                  value={email}
                  autoComplete="new-password"
                  disabled
                />

                <div className="ts-form-group__icon-container">
                  <img
                    className="ts-form-group__icon"
                    src="/assets/icons/user-circle.svg"
                    alt="..."
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-11">
                <TSFormControl
                  Icon="/assets/icons/password-icon.svg"
                  IconAlt="password icon"
                  Placeholder={
                    props.rtlSwitch
                      ? pageData.new_password_placeholder_ar
                      : pageData.new_password_placeholder_en
                  }
                  Type="password"
                  minLength="6"
                  changeValue={function (value) {
                    setNewPassword(value);
                  }}
                />
                {newPassword.length > 5 ? (
                  ""
                ) : (
                  <p className=" text-danger ms-4 mb-2">
                    {props.rtlSwitch
                      ? pageData.new_password_greater_then_6_feedback_ar
                      : pageData.new_password_greater_then_6_feedback_en}
                  </p>
                )}
                <TSFormControl
                  mb="mb-3"
                  Icon="/assets/icons/password-icon.svg"
                  IconAlt="password icon"
                  Placeholder={
                    props.rtlSwitch
                      ? pageData.new_confirm_password_placeholder_ar
                      : pageData.new_confirm_password_placeholder_en
                  }
                  Type="password"
                  minLength="6"
                  changeValue={function (value) {
                    setConfirmNewPassword(value);
                  }}
                />
                {confirmNewPassword.length > 5 ? (
                  ""
                ) : (
                  <p className=" text-danger ms-4 mb-2">
                    {props.rtlSwitch
                      ? pageData.confirm_new_password_greater_then_6_feedback_ar
                      : pageData.confirm_new_password_greater_then_6_feedback_en}
                  </p>
                )}

                {newPassword === confirmNewPassword ? (
                  ""
                ) : (
                  <p className=" text-danger ms-4 mb-0">
                    {props.rtlSwitch
                      ? pageData.password_same_feedback_ar
                      : pageData.password_same_feedback_en}
                  </p>
                )}
              </Form.Group>
              <Button
                className="btn-submit btn-primary-01 rounded-pill btn-lg w-100 btn--border-white shadow-sm "
                type="submit"
              >
                {props.rtlSwitch
                  ? pageData.submit_button_ar
                  : pageData.submit_button_en}
                <img
                  className="btn-img-end"
                  src="/assets/icons/arro-enter-right.svg"
                  alt="arrow right enter"
                />
              </Button>
            </Form>
          </div>
        </div>
      </Container>
    </section>
  );
}
