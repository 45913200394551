import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter as Router } from "react-router-dom";

import axios from "./helpers/AxiosConfig";

// let token = JSON.parse(localStorage.getItem("user-token"));

axios.interceptors.request.use((request) => {
  request.headers.DeveloperName = `Taimoor Salyhal`;

  request.headers.Authorization = `Bearer ${JSON.parse(
    localStorage.getItem("user-token")
  )}`;

  return request;
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <Router>
      <App />
    </Router>
  </React.Fragment>
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
