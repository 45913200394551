import "./page-not-found.scss";
import React from 'react'

import { Link } from "react-router-dom";

// data
import localData from "data/data.json";
let pageData = localData.page_not_found;

export default function PageNotFountContent(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 ">
                    <div className="col-sm-10 col-sm-offset-1  text-center mx-auto">
                        <div className="four_zero_four_bg">
                            <h1 className="text-center ">
                                {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
                            </h1>
                        </div>

                        <div className="contant_box_404">
                            <h3 className="h2">
                                {props.rtlSwitch
                                    ? pageData.subtitle_ar
                                    : pageData.subtitle_en}
                            </h3>

                            <p>{props.rtlSwitch ? pageData.desc_ar : pageData.desc_en}</p>

                            <Link to="/" className="btn btn-primary-01" style={{ width: "auto" }}>
                                {props.rtlSwitch
                                    ? pageData.goto_home_button_ar
                                    : pageData.goto_home_button_en}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
