import "./faq.scss";
import React, { useState, useEffect } from "react";
import axios from "helpers/AxiosConfig";

// Bootstrap
import { Container, Accordion } from "react-bootstrap";

// local data
import localData from "data/data.json";
let pageData = localData.faq_legal;

export default function LegalFaq(props) {
  const [legalFaqList, setLegalFaqList] = useState([]);

  useEffect(() => {
    axios
      .get("legal_faq")
      .then(function (response) {
        setLegalFaqList(response.data.legal_faq);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  }, []);

  return (
    <section className="ts-faq">
      <Container>
        <h1 className="text-gray-03 mb-11">
          {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
        </h1>

        <Accordion defaultActiveKey={["1"]} flush>
          {legalFaqList.map((legalFaqListItem) => (
            <Accordion.Item
              key={legalFaqListItem.id}
              eventKey={legalFaqListItem.id}
            >
              <Accordion.Header className="rounded-pill">
                <span>{legalFaqListItem.question_en}</span>
              </Accordion.Header>
              <Accordion.Body
                dangerouslySetInnerHTML={{
                  __html: legalFaqListItem.answer_en,
                }}
              ></Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Container>
    </section>
  );
}
