import "./login-and-register.scss";
import React, { useState, useEffect } from "react";
// Axios
import axios from "helpers/AxiosConfig";

import { Container, Form, Button } from "react-bootstrap";

import TSFormControl from "./TsFormControl";

import RehamDivaProgram from "../../assets/icons/reham-diva-program.svg";
import UserCircle from "../../assets/icons/user-circle.svg";

// import PasswordIcon from "../../assets/icons/password-icon.svg";
import ArrowEnterRight from "../../assets/icons/arro-enter-right.svg";

// Toast
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// data
import localData from "data/data.json";
let pageData = localData.forgot_password;
export default function ResetPassword(props) {
  const [email, setEmail] = useState();
  const [validated, setValidated] = useState(false);

  let forgetPassword = (event) => {
    event.preventDefault(); // prevent default behaviour
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    axios
      .get("forgot_password", {
        params: {
          email: email,
        },
      })
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };
  return (
    <section className="ts-login">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Container>
        <div className="ts-login__form-container  ">
          <div className="text-center">
            <img
              width="137"
              className="mb-10 mx-auto"
              src={RehamDivaProgram}
              alt="reham diva program logo"
            />
          </div>
          <div className="ts-login__form-container-inner ts-rounded ts-rounded--not-bottom-right">
            <Form
              className="ts-login__form"
              noValidate
              validated={validated}
              onSubmit={forgetPassword}
            >
              <h3 className="text-primary-03 mb-11 text-center ">
                {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
              </h3>

              <TSFormControl
                Icon={UserCircle}
                IconAlt="user circle icon"
                Placeholder={
                  props.rtlSwitch
                    ? pageData.email_placeholder_ar
                    : pageData.email_placeholder_en
                }
                Type="email"
                changeValue={function (value) {
                  setEmail(value);
                }}
              />

              <Button
                className="btn-submit btn-primary-01 rounded-pill btn-lg w-100 btn--border-white shadow-sm "
                type="submit"
              >
                {props.rtlSwitch
                  ? pageData.submit_button_ar
                  : pageData.submit_button_en}
                <img
                  className="btn-img-end"
                  src={ArrowEnterRight}
                  alt="arrow right enter"
                />
              </Button>
            </Form>
          </div>
        </div>
      </Container>
    </section>
  );
}
