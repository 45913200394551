import "./chapter.scss";
import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

// Bootstrap
import Container from "react-bootstrap/Container";

// Components
import TsBreadCrumb from "components/TsBreadCrumb/TsBreadCrumb";
import Lessons from "components/Lessons/Lessons";
// import Schedule from "common/js/Schedule";

export default function Chapter(props) {
  const [chapter, setChapter] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [breadCrumbList, setBreadCrumbList] = useState([
    { name: "My programs", routeLink: "/my-programs" },
    { name: "Lessons", routeLink: "/lessons" },
  ]);
  let { courseNumber, chapterNumber } = useParams();

  useEffect(() => {
    for (let course of props.myCourses) {
      if (course.id === parseInt(courseNumber)) {
        for (let chapter of course.chapters) {
          if (chapter.id === parseInt(chapterNumber)) {
            setChapter(chapter);
            setLessons(chapter.lessons);
            if (props.rtlSwitch) {
              setBreadCrumbList([
                { name: "برامجي", routeLink: "/my-programs" },
                { name: course.name_ar, routeLink: `/course/${course.id}` },
                {
                  name: chapter.name_ar,
                  routeLink: `/course/${course.id}/chapter/${chapter.id}`,
                },
                {
                  name: "الدروس",
                  routeLink: `/course/${course.id}/chapter/${chapter.id}`,
                },
              ]);
            } else {
              setBreadCrumbList([
                { name: "My programs", routeLink: "/my-programs" },
                { name: course.name_en, routeLink: `/course/${course.id}` },
                {
                  name: chapter.name_en,
                  routeLink: `/course/${course.id}/chapter/${chapter.id}`,
                },
                {
                  name: "Lessons",
                  routeLink: `/course/${course.id}/chapter/${chapter.id}`,
                },
              ]);
            }
          }
        }
      }
    }
  }, [courseNumber, chapterNumber, props.myCourses, props.rtlSwitch]);

  let toggleCompletion = (e) => {
    props.toggleCompletion(e);
  };

  useEffect(() => {
    props.updateChapterId(chapter.id);
  }, [courseNumber, chapterNumber, chapter]);

  return (
    <section className="ts-page-content">
      <Container className="pt-xxl-4">
        <TsBreadCrumb ItemList={breadCrumbList} />
        <header className="mb-14 mw-900">
          <h3>{props.rtlSwitch ? chapter.name_ar : chapter.name_en}</h3>
          <p
            className="ts-desc-lg text-gray-05 "
            dangerouslySetInnerHTML={{
              __html: props.rtlSwitch
                ? chapter.description_ar
                : chapter.description_en,
            }}
          ></p>
        </header>

        <Lessons
          localData={props.localData}
          lessons={lessons}
          rtlSwitch={props.rtlSwitch}
          toggleCompletion={toggleCompletion}
        />
      </Container>
    </section>
  );
}
