import React from "react";
// Bootstrap
import Container from "react-bootstrap/Container";
// Components
import MyPrograms from "components/Courses/MyPrograms";

// data
import localData from "data/data.json";
let pageData = localData?.my_programs;
export default function Programs(props) {
  return (
    <section className="ts-page-content">
      <Container>
        <h3 className="mb-09">
          {props?.rtlSwitch ? pageData?.title_ar : pageData?.title_en}
        </h3>

        {/* <button className="btn btn-primary-01 ts-rounded ts-rounded--not-top-right px-4 py-2 w-auto shadow border-white mb-11">
          {props.rtlSwitch ? "البرامج" : "Programs"}
        </button> */}

        <MyPrograms
          rtlSwitch={props?.rtlSwitch}
          isAdmin={props?.isAdmin}
          myCourses={props?.myCourses}
        />
      </Container>
    </section>
  );
}
