import React from "react";
import "./courses.scss";

import SingleCourse3 from "./SingleCourse3";

export default function CoursesGroup1(props) {
  return (
    <div
      className={
        "ts-courses-group " +
        (props.groupCol === "2" ? "ts-courses-group--2-cards" : "")
        // (props.isCenter === "2" ? "ts-courses-group--2-cards" : "")
      }
    >
      {props.Courses.map((course, index) => {
        return (
          <SingleCourse3
            key={index}
            CourseTitle={props.rtlSwitch ? course.name_ar : course.name_en}
            CourseBtn={props.rtlSwitch ? "تفاصيل الدورة" : "Course Detail"}
            Id={course.id}
            addCourseToWishList={props.addCourseToWishList}
            addToCart={props.addToCart}
          />
        );
      })}
    </div>
  );
}
