import React from "react";
import Form from "react-bootstrap/Form";

export default function QuizQuestion(props) {
  // console.log(props.question.type)

  if (props.question.type === "multiple") {
    return (
      <div className="mb-08 ts-quiz-question">
        <h4 className="fw-normal">
          {props.rtlSwitch
            ? props.question.question_ar
            : props.question.question_en}
        </h4>

        <ul className="list-unstyled ps-3">
          {props.rtlSwitch
            ? props.question.possible_answer_ar.map((ans, index) => {
                return (
                  <li
                    key={index}
                    className={`ts-ans d-flex gap-3 mb-04 ${
                      index === parseInt(props.question.currect_answer)
                        ? `ts-correct-ans ts-correct-ans-${props.question.id} `
                        : ""
                    }`}
                  >
                    <Form.Check
                      name={`id-${props.question.id}`}
                      type="radio"
                      aria-label={`radio-${props.question.quiz_id}`}
                      label={ans}
                    />
                  </li>
                );
              })
            : props.question.possible_answer_en.map((ans, index) => {
                return (
                  <li
                    key={index}
                    className={`ts-ans d-flex gap-3 mb-04 ${
                      index === parseInt(props.question.currect_answer)
                        ? `ts-correct-ans ts-correct-ans-${props.question.id} `
                        : ""
                    }`}
                  >
                    <Form.Check
                      name={`id-${props.question.id}`}
                      type="radio"
                      aria-label={`radio-${props.question.quiz_id}`}
                      label={ans}
                    />
                  </li>
                );
              })}
        </ul>
      </div>
    );
  }
  if (props.question.type === "boolean") {
    return (
      <div className="mb-08 ts-quiz-question">
        <h4 className="fw-normal">
          {props.rtlSwitch
            ? props.question.question_ar
            : props.question.question_en}
        </h4>

        <ul className="list-unstyled ps-3">
          <li
            className={`d-flex gap-3 mb-04  ${
              props.question.currect_answer ? "ts-correct-ans" : ""
            }`}
          >
            <Form.Check
              id={`id-${props.question.id}`}
              name={`id-${props.question.id}`}
              type="radio"
              aria-label={`radio-${props.question.quiz_id}`}
              label={props.rtlSwitch ? "إجابة صحيحة" : "True"}
            />
          </li>
          <li
            className={`d-flex gap-3 mb-04  ${
              props.question.currect_answer ? "" : "ts-correct-ans"
            }`}
          >
            <Form.Check
              id={`id-${props.question.id}`}
              name={`id-${props.question.id}`}
              type="radio"
              aria-label={`radio-${props.question.quiz_id}`}
              label={props.rtlSwitch ? "إجابة خاطئة" : "False"}
            />
          </li>
        </ul>
      </div>
    );
  } else if (props.question.type === "descriptive") {
    return (
      <div className="mb-08 ts-quiz-question">
        <h4 className="fw-normal">
          {props.rtlSwitch
            ? props.question.question_ar
            : props.question.question_en}
        </h4>

        <Form.Group className="mb-04 ps-3">
          <Form.Control
            className="ts-descriptive-question"
            as="textarea"
            rows={6}

            placeholder={props.rtlSwitch ? "إجابة مفتوحة" : "Description"}
          />
        </Form.Group>
      </div>
    );
  } else {
    return (
      <div className="mb-08 ts-quiz-question">
        <h4 className="fw-normal">
          {props.rtlSwitch
            ? props.question.question_ar
            : props.question.question_en}
        </h4>

        <ul
          className={`list-unstyled ps-3 ts-multiple-choice-question-${props.question.id}`}
        >
          {props.rtlSwitch ? (
            <>
              {props.question.possible_answer_ar.map((ans, index) => {
                return (
                  <li
                    key={index}
                    className={`ts-ans d-flex gap-3 mb-04 ${props.question.currect_answer.map(
                      (correctAns) => {
                        return parseInt(correctAns) === index
                          ? ` ts-correct-ans ts-correct-ans-${props.question.id} `
                          : ` `;
                      }
                    )}`}
                  >
                    <Form.Check
                      name={`id-${props.question.id}`}
                      type="checkbox"
                      aria-label={`check-${props.question.quiz_id}`}
                      label={ans}
                    />
                  </li>
                );
              })}
            </>
          ) : (
            <>
              {props.question.possible_answer_en.map((ans, index) => {
                return (
                  <li
                    key={index}
                    className={`ts-ans d-flex gap-3 mb-04 ${props.question.currect_answer.map(
                      (correctAns) => {
                        return parseInt(correctAns) === index
                          ? ` ts-correct-ans ts-correct-ans-${props.question.id} `
                          : ` `;
                      }
                    )}`}
                  >
                    <Form.Check
                      name={`id-${props.question.id}`}
                      type="checkbox"
                      aria-label={`check-${props.question.quiz_id}`}
                      label={ans}
                    />
                  </li>
                );
              })}
            </>
          )}
        </ul>
      </div>
    );
  }
}
