import "./subscribe-email.scss";
import { useEffect, useState } from "react";

import axios from "helpers/AxiosConfig";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// data
import localData from "data/data.json";
let pageData = localData.stay_in_touch;

export default function SubscribeEmail(props) {
  const [email, setEmail] = useState("");
  const [validated, setValidated] = useState(false);

  useEffect(() => { }, []);

  let stayInTouchForm = async (event) => {
    event.preventDefault(); // prevent default behaviour

    // bootstrap Validation
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault(); // prevent default behaviour
      event.stopPropagation();
    }
    setValidated(true);

    axios
      .post("newsletter", {
        email: email,
      })
      .then((response) => {
        let message = response.data.message;

        if (response.data.success === true) {
          toast.success(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // setEmail("");
        }
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Form
        className={`ts-form-subscribe ts-rounded  ${props.rtlSwitch
            ? "ts-rounded--not-top-left"
            : "ts-rounded--not-bottom-right"
          } `}
        noValidate
        validated={validated}
      >
        <div className="ts-form-subscribe__content">
          <h3 className="mb-12 ">
            {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
          </h3>
          <Form.Group className="mb-04 " controlId="formBasicEmail">
            <Form.Control
              size="lg"
              className="rounded-pill form-control-lg "
              type="email"
              placeholder={
                props.rtlSwitch
                  ? pageData.input_placeholder_ar
                  : pageData.input_placeholder_en
              }
              value={email}
              required
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Form.Group>
          <Button
            variant="primary"
            className="btn-lg btn-white fw-bold rounded-pill w-100 "
            type="submit"
            onClick={stayInTouchForm}
          >
            {props.rtlSwitch ? pageData.button_ar : pageData.button_en}
          </Button>
        </div>
      </Form>
    </>
  );
}
