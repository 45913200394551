// Axios
import axios from "../../helpers/AxiosConfig";
import { toast } from "react-toastify";

// Check the use is admin or not
export const lessonComplete = (lessonId) => {
  axios
    .post("lesson_completed", {
      lesson_id: lessonId,
    })
    .then((response) => {
      toast.success(response.data.message, {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch(function (error) {
      console.log(error); // handle error
    });
};
export const lessonUnComplete = (lessonId) => {
  axios
    .post("remove_lesson_completed", {
      lesson_id: lessonId,
    })
    .then((response) => {
      toast.success(response.data.message, {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch(function (error) {
      console.log(error); // handle error
    });
};
