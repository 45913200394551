import "./review.scss";
import React, { useEffect, useState } from "react";

import { Stack } from "react-bootstrap";
import PostComment from "./PostComment";

// import { PersonPlus } from "react-bootstrap-icons";

export default function SingleReview(props) {
  const [reviewUpdatedDate, setReviewUpdatedDate] = useState();
  const [isChildrenReviewAbailable, setIsChildrenReviewAbailable] =
    useState(false);

  const [activeCommentReview, setActiveCommentReview] = useState(
    props.comment.id
  );
  const [isAddComment, setIsAddComment] = useState(false);
  useEffect(() => {
    // review updated date
    setReviewUpdatedDate(props.comment.updated_at.split("T")[0]);
    // check if children review availalbe
    if (props.comment.all_children.length !== 0) {
      setIsChildrenReviewAbailable(true);
    } else {
      setIsChildrenReviewAbailable(false);
    }
  }, []);

  // // Toggle comment post
  // let togglePostCommentReply = (commentId) => {

  //   if (commentId === activeCommentReview) {
  //     setActiveCommentReview(null);
  //   } else {
  //     setActiveCommentReview(commentId);
  //   }
  // };

  return (
    <>
      <div className="ts-review__inner">
        <header className="ts-review__header  d-flex align-items-center">
          <img className="ts-review__user-img" src="/assets/icons/profile-picture.svg" alt="..." />
        </header>
        <div className="ts-review__main">
          <Stack
            direction="horizontal"
            gap={3}
            className="align-items-center mb-03"
          >
            <h6 className="ts-review__name text-gray-05 mb-0">
             
            	{(props.comment.user?.name || " ")}
            </h6>
            <p className="ts-review__date text-nowrap text-gray-05 fw-bold mb-0">
              {reviewUpdatedDate}
            </p>
          </Stack>

          <p className="ts-review__message text-gray-05 mb-03">
            {props.comment.comment}
          </p>

          <Stack direction="horizontal" gap={5}>
            <Stack
              direction="horizontal"
              gap={3}
              className="ts-review__likes align-items-center"
            >
              <img
                className="ts-review__likes-thumb"
                width="31"
                src="/assets/icons/Icon-Like.svg"
                alt="..."
                onClick={() => props.ToggleLike(props.comment.id)}
              />
              <h5 className=" mb-0 text-gray-05">{props.comment.like}</h5>
            </Stack>
            {/* {props.isAdmin ? ( */}
            <button
              className="btn btn-primary-07"
              onClick={() => setIsAddComment(!isAddComment)}
            >
              {isAddComment ? "Close" : "Reply"}
            </button>
            {/* ) : (
              ""
            )} */}
          </Stack>
        </div>
      </div>
      {/* If childresn availalbe*/}
      {isAddComment ? (
        <PostComment
          lessonId={props.lessonId}
          parentId={props.comment.id}
          rtlSwitch={props.rtlSwitch}
        />
      ) : (
        ""
      )}

      {isChildrenReviewAbailable ? (
        <ul className="ts-review__children">
          {props.comment.all_children.map((comment, index) => {
            return (
              <li className="ts-review__children-item" key={index}>
                <header className="ts-review__header  d-flex align-items-center">
                  <img
                    className="ts-review__user-img"
                 
                    src="/assets/icons/profile-picture.svg"
                    alt="..."
                  />
                </header>
                <div className="ts-review__main ">
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="align-items-center mb-03 "
                  >
                    <h6 className="ts-review__name text-gray-05 mb-0">
                      
						{(comment.user?.name || " ")}
                      {/* {props.rtlSwitch ? "رهام الرشيدي" : "Reham Alrashidi"} */}
                    </h6>
                    <p className="ts-review__date text-nowrap text-gray-05 fw-bold mb-0">
                      {reviewUpdatedDate}
                    </p>
                  </Stack>

                  <p className="ts-review__message text-gray-05 mb-03">
                    {comment.comment}
                  </p>

                  <Stack direction="horizontal" gap={5}>
                    <Stack
                      direction="horizontal"
                      gap={3}
                      className="ts-review__likes align-items-center"
                    >
                      <img
                        className="ts-review__likes-thumb"
                        width="31"
                        src="/assets/icons/Icon-Like.svg"
                        alt="..."
                        onClick={() => props.ToggleLike(comment.id)}
                      />
                      <h5 className=" mb-0 text-gray-05">{comment.like}</h5>
                    </Stack>
                  </Stack>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        ""
      )}
    </>
  );
}
