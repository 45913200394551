import "./seminar.scss";
import React from "react";

export default function SeminarCard(props) {
  return (
    <div className="ts-seminar-card">
      <div className="ts-seminar-card__header">
        <img
          className="ts-seminar-card__img"
          src={props.card.image_url}
          alt="..."
        />
      </div>
      <div className="ts-seminar-card__body">
        <h4>{props.rtlSwitch ? props.card.title_ar : props.card.title_en}</h4>
        <p className="ts-desc-sm">
          {props.rtlSwitch
            ? props.card.description_ar
            : props.card.description_en}
        </p>
      </div>
    </div>
  );
}
