import "./ts-breadcrumb.scss";
import React from "react";

// Bootstrap
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Stack from "react-bootstrap/Stack";
// react router
import { Link } from "react-router-dom";
// images
import IconHome from "../../assets/icons/Icon-Home.svg";
export default function TsBreadCrumb(props) {
  return (
    <Stack direction="horizontal" gap={3} className="align-items-start mb-09">
      <div>
        <img width="27" src={IconHome} alt="home icon" />
      </div>
      <Breadcrumb>
        {props.ItemList.map((item, index) => {
          return (
            <li className="breadcrumb-item" key={index}>
              <Link to={item.routeLink}>{item.name}</Link>
            </li>
          );
        })}
      </Breadcrumb>
    </Stack>
  );
}
