import "./lesson-tabs.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import LessonsTabsData from "./LessonsTabsData";

export default function LessonsTabs(props) {
  return (
    <div className="ts-lessons-tabs">
      <Tabs
        defaultActiveKey="0"
        id="tsCoursesTabs"
        className="mb-3"
        data-tabs-courses={props.courses.length}
      >
        {props.courses.map((course, index) => {
          return (
            <Tab
              eventKey={index}
              title={props.rtlSwitch ? course.name_ar : course.name_en}
              key={index}
            >
              <LessonsTabsData rtlSwitch={props.rtlSwitch} course={course} />
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
}
