import React from "react";
import getVideoId from "get-video-id";
export default function LessonTabLesson(props) {
  return (
    <div className="ts-lesson-card ">

      {props.lesson.vimeo_url ?
        <img
          className="ts-lesson-card__img"
          src={`https://vumbnail.com/${getVideoId(props.lesson.vimeo_url).id
            }_medium.jpg`}
          alt="..."
        />
        :
        (
          <img
            className="ts-lesson-card__img"
            src={props.lesson.thumb_image_url}
            alt="..."
          />
        )
      }
      <div className="ts-lesson-card__body text-center">
        <p className="mb-0 text-primary-02 fw-bold ">
          {props.rtlSwitch ? props.lesson.name_ar : props.lesson.name_en}
        </p>
      </div>
    </div>
  );
}
