import "./profile-menu.scss";
import { Link } from "react-router-dom";
import { Stack, Button, Modal } from "react-bootstrap";

// data
import localData from "data/data.json";
let pageData = localData.profile_menu_modal;

export default function ProfileMenuModal(props) {
  return (
    <Modal
      className="ts-profile-modal"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-content-inner">
        <Modal.Body>
          <div className="ts-profile-modal-container ts-rounded ts-rounded--not-top-right">
            <Button className="btn close-btn" onClick={props.onHide}>
              <img src="/assets/icons/Icon-plus.svg" alt="close btn" />
            </Button>
            <Stack
              gap={3}
              className="flex-md-row align-items-center mb-11 text-center text-md-start"
            >
              <div>
                <img src="/assets/icons/profile-picture.svg" alt="profile" />
              </div>
              <div>
                <h3 className="mb-03">{props.username}</h3>
                <p className="heading-5 mb-0">{props.useremail}</p>
              </div>
            </Stack>
            <ul className="ts-profile-modal__list text-gray-05">
              <li>
                <Link onClick={props.onHide} to="/profile">
                  {props.rtlSwitch
                    ? pageData.update_profile_enabled_ar
                    : pageData.update_profile_enabled_en}
                </Link>
              </li>
              <li>
                <Link onClick={props.onHide} to="/payment-method">
                  {props.rtlSwitch
                    ? pageData.payment_method_ar
                    : pageData.payment_method_en}
                </Link>
              </li>
              <li>
                <Link onClick={props.onHide} to="/reset-password">
                  {props.rtlSwitch
                    ? pageData.change_password_ar
                    : pageData.change_password_en}
                </Link>
              </li>
              <li>
                <Link onClick={props.onHide} to="/legal-faq">
                  {props.rtlSwitch
                    ? pageData.legal_faq_ar
                    : pageData.legal_faq_en}
                </Link>
              </li>
              <li>
                <Link onClick={props.onHide} to="/technical-support">
                  {props.rtlSwitch
                    ? pageData.technical_support_ar
                    : pageData.technical_support_en}
                </Link>
              </li>
              <li role="button" onClick={props.onLogout}>
                {/* <Link to="/login"> */}
                {props.rtlSwitch ? pageData.logout_ar : pageData.logout_en}
                {/* </Link> */}
              </li>
            </ul>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}
