import "./confirmation-modal.scss";
import { Stack, Button, Modal } from "react-bootstrap";

// data
import localData from "data/data.json";
let pageData = localData.confirmation_modal;
export default function ConfirmationModal(props) {
  return (
    <Modal
      className="ts-confirmation-modal "
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-content-inner">
        <Button className="btn close-btn" onClick={props.onHide}>
          <img src="/assets/icons/Icon-plus.svg" alt="close btn" />
        </Button>
        <Modal.Body>
          <h2 className="mb-10">{props.confirmtitle}</h2>
          <p className="heading-4 mb-09">{props.confirmdesc}</p>
          <Stack direction="horizontal" className="justify-content-around">
            <Button variant="primary-07" onClick={props.onUserLogout}>
              {props.rtlSwitch ? pageData.cta_yes_ar : pageData.cta_yes_en}
            </Button>
            <Button variant="primary-07" onClick={props.onHide}>
              {props.rtlSwitch ? pageData.cta_no_ar : pageData.cta_no_en}
            </Button>
          </Stack>
        </Modal.Body>
      </div>
    </Modal>
  );
}
