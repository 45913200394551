import "./login-and-register.scss";
import React, { useState, useEffect } from "react";
import axios from "helpers/AxiosConfig";
import { Link, useNavigate } from "react-router-dom";

import { Container, Form, Button } from "react-bootstrap";

import TSFormControl from "./TsFormControl";
import TsFormSelectCountries from "./TsFormSelectCountries";

import { SaveUserDataToLocalStorage } from "common/js/LoginHelpers";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// data
import localData from "data/data.json";
let pageData = localData.register;

export default function Register(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  // Countries list
  const [countryList, setCountryList] = useState([]);
  const [isFormFilled, setIsFormFilled] = useState(false);

  // Bootstrap validation
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    CheckValidation();
  }, [
    firstName,
    lastName,
    country,
    mobileNumber,
    email,
    confirmEmail,
    password,
  ]);

  useEffect(() => {
    getCountries();
  }, []);
  // useEffect(() => {
  //   console.log(country);
  // }, [setCountry]);

  const getCountries = () => {
    axios
      .get("countries", {})
      .then((response) => {
        setCountryList(response.data.countries);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };

  const RegisterAccount = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    if (
      email === confirmEmail &&
      firstName !== "" &&
      lastName !== "" &&
      country !== "" &&
      mobileNumber !== "" &&
      email !== "" &&
      confirmEmail !== "" &&
      password !== ""
    ) {
      axios
        .post("register", {
          first_name: firstName,
          last_name: lastName,
          mobile_no: mobileNumber,
          country_id: country,
          email: email,
          password: password,
        })
        .then((response) => {
          let userData = JSON.stringify(response.data.data);
          let userToken = JSON.stringify(response.data.token);
          let success = response.data.success;
          let message = props.rtlSwitch
            ? pageData.password_placeholder_ar
            : pageData.password_placeholder_en;

          // console.log(response);
          if (success) {
            toast.success(message, {
              position: "bottom-right",
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            // Save data to local storage
            SaveUserDataToLocalStorage(userData, userToken);
            navigate("/login");
          } else {
            // const errorMessages = ["asdfasf", "asdfasdf"];
            const errorMessages = response.data.error.email;

            for (message of errorMessages) {
              toast.error(message, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        })
        .catch(function (error) {
          console.log(error); // handle error
          let message = error.message;

          toast.error(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      let inValidFormmessage = props.rtlSwitch
        ? pageData.invalid_form_submit_ar
        : pageData.invalid_form_submit_en;
      toast.error(inValidFormmessage, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const CheckValidation = () => {
    if (
      firstName !== "" &&
      lastName !== "" &&
      country !== "" &&
      mobileNumber !== "" &&
      email !== "" &&
      confirmEmail !== "" &&
      password !== ""
    ) {
      setIsFormFilled(true);
    } else {
      setIsFormFilled(false);
    }
  };
  return (
    <section className="ts-register">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Container>
        <div className="ts-login__form-container  ">
          <div className="text-center">
            <img
              width="137"
              className="mb-10 mx-auto"
              src="./assets/icons/reham-diva-program.svg"
              alt="reham diva program logo"
            />
          </div>
          <div className="ts-login__form-container-inner ts-rounded ts-rounded--not-bottom-right">
            <Form
              className="ts-login__form mb-05"
              noValidate
              validated={validated}
            >
              <h3 className="text-primary-03 mb-08 ">
                {props.rtlSwitch
                  ? pageData.form_title_ar
                  : pageData.form_title_en}
              </h3>
              {/* <h4 className="text-primary-03 fw-normal mb-08">
                It's free and always will be.
              </h4> */}

              <TSFormControl
                Icon="./assets/icons/user-circle.svg"
                IconAlt="user circle icon"
                Placeholder={
                  props.rtlSwitch
                    ? pageData.first_name_placeholder_ar
                    : pageData.first_name_placeholder_en
                }
                InvalidFeedback={
                  props.rtlSwitch
                    ? pageData.first_name_invalid_feedback_ar
                    : pageData.first_name_invalid_feedback_en
                }
                minLength="1"
                maxLength="20"
                Type="text"
                autoComplete="off"
                changeValue={(value) => {
                  setFirstName(value);
                  CheckValidation();
                }}
              />

              <TSFormControl
                Icon="./assets/icons/user-circle.svg"
                IconAlt="user circle icon"
                Placeholder={
                  props.rtlSwitch
                    ? pageData.last_name_placeholder_ar
                    : pageData.last_name_placeholder_en
                }
                InvalidFeedback={
                  props.rtlSwitch
                    ? pageData.last_name_invalid_feedback_ar
                    : pageData.last_name_invalid_feedback_en
                }
                minLength="1"
                maxLength="20"
                Type="text"
                autoComplete="off"
                changeValue={(value) => {
                  setLastName(value);
                  CheckValidation();
                }}
              />
              <TsFormSelectCountries
                Icon="./assets/icons/flag-primary.svg"
                IconAlt="flag icon"
                dataArray={countryList}
                defaultDisabledOption={
                  props.rtlSwitch
                    ? pageData.country_placeholder_ar
                    : pageData.country_placeholder_en
                }
                InvalidFeedback={
                  props.rtlSwitch
                    ? pageData.country_invalid_feedback_ar
                    : pageData.country_invalid_feedback_en
                }
                autoComplete="off"
                changeValue={(value) => {
                  setCountry(value);
                  CheckValidation();
                }}
              />
              <TSFormControl
                Icon="./assets/icons/drag-indicator-primary.svg"
                IconAlt="dail icon"
                Placeholder={
                  props.rtlSwitch
                    ? pageData.mobile_number_placeholder_ar
                    : pageData.mobile_number_placeholder_en
                }
                InvalidFeedback={
                  props.rtlSwitch
                    ? pageData.mobile_number_invalid_feedback_ar
                    : pageData.mobile_number_invalid_feedback_en
                }
                Type="tel"
                autoComplete="off"
                // minLength="8"
                // maxLength="8"
                changeValue={(value) => {
                  setMobileNumber(value);
                  CheckValidation();
                }}
              />
              <TSFormControl
                Icon="./assets/icons/email_primary-icon.svg"
                IconAlt="mail icon"
                Placeholder={
                  props.rtlSwitch
                    ? pageData.email_placeholder_ar
                    : pageData.email_placeholder_en
                }
                InvalidFeedback={
                  props.rtlSwitch
                    ? pageData.email_invalid_feedback_ar
                    : pageData.email_invalid_feedback_en
                }
                Email
                Type="email"
                autoComplete="off"
                changeValue={(value) => {
                  setEmail(value);
                  CheckValidation();
                }}
              />
              <TSFormControl
                Icon="./assets/icons/email_primary-icon.svg"
                IconAlt="mail icon"
                Placeholder={
                  props.rtlSwitch
                    ? pageData.confirm_email_placeholder_ar
                    : pageData.confirm_email_placeholder_en
                }
                InvalidFeedback={
                  props.rtlSwitch
                    ? pageData.confirm_email_invalid_feedback_ar
                    : pageData.confirm_email_invalid_feedback_en
                }
                Type="email"
                autoComplete="off"
                changeValue={(value) => {
                  setConfirmEmail(value);
                  CheckValidation();
                }}
              />

              {email === confirmEmail ? (
                ""
              ) : (
                <p className=" text-danger ms-4">
                  {props.rtlSwitch
                    ? pageData.email_confrim_email_same_feedback_ar
                    : pageData.email_confrim_email_same_feedback_en}
                </p>
              )}
              <TSFormControl
                mb="mb-08"
                Icon="./assets/icons/password-icon.svg"
                IconAlt="mail icon"
                Placeholder={
                  props.rtlSwitch
                    ? pageData.password_placeholder_ar
                    : pageData.password_placeholder_en
                }
                InvalidFeedback={
                  props.rtlSwitch
                    ? pageData.password_invalid_feedback_ar
                    : pageData.password_invalid_feedback_en
                }
                Type="password"
                autoComplete="off"
                minLength="6"
                changeValue={(value) => {
                  setPassword(value);
                  CheckValidation();
                }}
              />

              <Button
                className={`btn-submit btn-primary-01 rounded-pill btn-lg w-100 btn--border-white shadow-sm ${isFormFilled ? "" : "ts-disabled"
                  }  `}
                type="submit"
                onClick={RegisterAccount}
              >
                {props.rtlSwitch
                  ? pageData.register_button_ar
                  : pageData.register_button_en}
                <img
                  className="btn-img-end"
                  src="./assets/icons/arro-enter-right.svg"
                  alt="arrow right enter"
                />
              </Button>
            </Form>

            <p className="text-center fw-bold">
              {props.rtlSwitch
                ? pageData.go_to_login_ar
                : pageData.go_to_login_en}
              &nbsp;
              <Link
                className="text-decoration-none text-primary-02 "
                to="/login"
              >
                {props.rtlSwitch
                  ? pageData.go_to_login_link_ar
                  : pageData.go_to_login_link_en}
              </Link>
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
}
