import React from "react";
import "./courses.scss";

import SingleCourse2 from "./SingleCourse2";

export default function CoursesGroup1(props) {
  return (
    <div>
      <h2 className="text-primary-04 mb-11">{props.CoursesTitle}</h2>
      <div className="ts-courses-group">
        {props.SpecialCourseslist.map((course, index) => {
          return (
            <SingleCourse2
              key={index}
              Id={course.id}
              CourseName={props.rtlSwitch ? course.name_ar : course.name_en}
              CourseBtn={props.rtlSwitch ? "تفاصيل الدورة" : "Course Detail"}
            />
          );
        })}
      </div>
    </div>
  );
}
