import "./custom-form.scss";
import React, { useState, useEffect } from "react";
import axios from "helpers/AxiosConfig";

import { Form, Button } from "react-bootstrap";

// import MailchimpSubscribe from "react-mailchimp-subscribe";

export default function CustomForm({ status, message, onValidated }) {
  const [countryList, setCountryList] = useState([]);
  const [email, setEmail] = useState("");
  const [omar, setOmar] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [nation, setNation] = useState("");

  const [validated, setValidated] = useState(false);
  // message
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  useEffect(() => {
    if (onValidated) {
      clearFields();
    }
    getCountries();

    if (status === "success") {
      setIsFormSubmited(true);
    }
  }, [status]);

  const clearFields = () => {
    setEmail("");
    setOmar("");
    setMaritalStatus("");
    setEducationLevel("");
    setNation("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    // if (omar === "notValid" || omar === undefined) {
    //   console.log("please select age value");
    // }

    setValidated(true);
    email &&
      email &&
      omar &&
      maritalStatus &&
      educationLevel &&
      nation &&
      email.indexOf("@") > -1 &&
      onValidated({
        MERGE0: email,
        MERGE1: omar,
        MERGE2: maritalStatus,
        MERGE3: educationLevel,
        MERGE4: nation,
      });
  };

  const getCountries = () => {
    axios
      .get("countries")
      .then((response) => {
        setCountryList(response.data.countries);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };
  return (
    <>
      <Form
        className="ts-custom-form"
        onSubmit={(e) => handleSubmit(e)}
        noValidate
        validated={validated}
      >
        {status === "sending" && (
          <div className="text-success mb-05">Sending...</div>
        )}

        <Form.Group className="mb-07" controlId="formBasicEmail">
          <Form.Control
            type="email"
            placeholder="البريد الإلكتروني"
            size="lg"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group className="mb-07" controlId="age">
          <Form.Select
            size="lg"
            onChange={(e) => setOmar(e.target.value)}
            required
            // isInvalid={omar == "notValid" || omar == undefined}
          >
            <option value="" disabled selected>
              العمر
            </option>
            <option value="١٨ وأصغر "> ١٨ وأصغر </option>
            <option value=" ١٩-٢٤ "> ١٩-٢٤ </option>
            <option value=" ٢٥-٣٤ "> ٢٥-٣٤ </option>
            <option value=" ٣٥-٤٤ "> ٣٥-٤٤ </option>
            <option value=" ٤٥-٥٤ "> ٤٥-٥٤ </option>
            <option value=" ٥٥ وأكبر "> ٥٥ وأكبر </option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-07" controlId="formBasicEmail">
          <Form.Select
            size="lg"
            aria-label="Default select example"
            onChange={(e) => setMaritalStatus(e.target.value)}
            required
          >
            <option value="" disabled selected>
              الحالة الاجتماعية
            </option>
            <option value=" أعزب "> أعزب </option>
            <option value=" متزوج "> متزوج </option>
            <option value=" أرملة "> أرملة </option>
            <option value=" مُطلّق "> مُطلّق </option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-07" controlId="formBasicEmail">
          <Form.Select
            size="lg"
            aria-label="Default select example"
            onChange={(e) => setEducationLevel(e.target.value)}
            required
          >
            <option value="" disabled selected>
              مستوى التعليم
            </option>
            <option value=" دون الثانوية "> دون الثانوية </option>
            <option value=" ثانوية عامة "> ثانوية عامة </option>
            <option value=" دبلوم "> دبلوم </option>
            <option value=" درجة جامعية "> درجة جامعية </option>
            <option value=" دراسات عليا "> دراسات عليا </option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-07" controlId="formBasicEmail">
          <Form.Select
            size="lg"
            aria-label="Default select example"
            onChange={(e) => setNation(e.target.value)}
            required
          >
            <option value="" disabled selected>
              البلد
            </option>

            {countryList.map((coutry) => {
              return (
                <option key={coutry.id} value={coutry.name}>
                  {coutry.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>

        <Button
          className="w-100 mb-07"
          variant="primary-07"
          type="submit"
          formValues={[email, omar, maritalStatus, educationLevel, nation]}
        >
          سجل الآن
        </Button>
        {isFormSubmited ? (
          <div className="ts-success-form mb-07">
            <p className="mb-0 ts-desc-sm">The Form was send successfully!</p>
          </div>
        ) : (
          ""
        )}
        <p className="ts-desc-sm text-center mb-10">
          نحترم خصوصيتك لن يتم إرسال اعلانات تجارية
        </p>
      </Form>
    </>
  );
}
