import React from "react";
import "./courses.scss";
import BeatLoader from "react-spinners/BeatLoader";
import ProgramCard from "./ProgramCard";

export default function Programs(props) {
  const { loading } = props;

  return (
    <div>
      <h2 className="text-primary-04 mb-11">{props.CoursesTitle}</h2>
      <h1>{props.rtlSwitch}</h1>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <BeatLoader color="#eb8eba" />
        </div>
      ) : (
        <div className="ts-courses-group">
          {props.ProgramsList.map((program, index) => {
            return (
              <ProgramCard
                key={index}
                Title={props.rtlSwitch ? program.name_ar : program.name_en}
                Description={
                  props.rtlSwitch ? program.caption_ar : program.caption_en
                }
                IconUrl={program.thumb_image_url}
                Id={program.id}
                Btn={props.rtlSwitch ? "عرض" : "view"}
                isLogged={props.isLogged}
                // Btn="View"
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
