import React from "react";
import { Link } from "react-router-dom";
export default function ProgramCard(props) {
  return (
    <div className="ts-course-card">
      <h4 className="ts-course-card__title mb-09">{props.Title}</h4>
      <img
        className="ts-course-card__center-img mb-13"
        src={props.IconUrl}
        alt="reham course "
      />

      <p className="ts-course-card__desc ts-desc-sm mb-07">
        {props.Description}
      </p>
      <Link
        className="ts-course-card__btn btn"
        to={`/program-detail/${+props.Id}`}
      >
        {props.Btn}
      </Link>
    </div>
  );
}
