import "./about.scss";
import React, { useEffect, useState } from "react";
import axios from "helpers/AxiosConfig";
// Bootstrap
import Container from "react-bootstrap/Container";

// data
import localData from "data/data.json";
let pageData = localData.about;
export default function About(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    getAboutData();
  }, []);

  const getAboutData = () => {
    axios
      .get(`about_us`)
      .then(function (response) {
        setData(response.data.data);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };
  return (
    <section className="ts-about">
      <Container>
        <h2 className="text-gray-05 mb-08 ts-user-login">
          {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
        </h2>
        <div className="ts-about__main">
          <div>
            <img
              className="w-100 ts-rounded ts-rounded--not-top-right shadow-sm"
              src={data.about_us_image}
              alt="..."
            />
          </div>
          <div>
            <h2 className="text-gray-05 mb-08 ts-user-not-login">
              {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
            </h2>
            <p className="ts-desc-md text-gray-01">
              {props.rtlSwitch ? data.about_ar : data.about_en}
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
}
